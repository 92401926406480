import React,{useEffect} from "react";
import { connect } from 'react-redux';
import { Row, Col, Form, FormGroup, Label, Input,Modal,ModalHeader,ModalBody,ModalFooter,Button } from 'reactstrap';
import DashboardHeader from "../includes/dashboardHeader/DashboardHeader";
import DashboardSidebar from "../includes/dashboardSidebar/DashboardSidebar";
import FooterComponent from "../includes/dashboardFooter/FooterComponent";
import AsideComponent from "../includes/asideComponent/AsideComponent";
import {Prompt} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import SidebarJs from "../../common/js/sidebarAnimation";
import './capabilityMapping.css';
import ApiServer from "./../../common/js/ApiServices.js";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import { Link } from 'react-router-dom';
import {responseMessage} from '../../utils/alert';
import { startLoader, stopLoader } from '../../actions/loaderActions';

import axios from 'axios';
const config = require('../../config');


class CapabilityMapping extends React.Component {
  constructor(props) {
    super(props);
    this.sidebarAnimation = new SidebarJs();
    this.apiServer = new ApiServer(true);
    this.capabilityModellingProjectData = []; 
    this.benchmarkProjectData = []; 
    this.userDomainsData = [];
    this.capabilityData = [];
    this.mappingMatrixData = [];

    this.state = {
      finalizeMapping:false,
      versionClicked:false,
      updateVersion:false,      
      hasInprogressData:false,
      selectedDecompositionProjectID: 0,
      selectedDecompositionProjectName: '',
      selectedCapabilityMappingName: '',
      selectedBenchmarkProjectID:0,
      selectedBenchmarkProjectName:'',
      selectedCapabilityModellingProject: [],
      capabilityModellingProjectData: [],
      benchmarkProjectData: [],
      userDomainsData: [],
      capabilityData: [],
      allCapabilityIdArr:[],
      checkedCapabilityIdArr:[],
      tempCapabilityMappingID:0,
      CapabilityMappingID: 0,
      latestVersionID:0,
      templatestVersionID:0,
      matrixDisplayLabel:'',
      mappingMatrixData:[],
      mappingModificationStarted:false,
      selectButtonDisableStatus:true,      
      versionData:[],
      saveProgressButtonText: "Save Progress",
      validationError: {
        assesmentName: false
      }
    };

    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
}

beforeunload(e) {  
    if (this.state.mappingModificationStarted) {
      e.preventDefault();
      e.returnValue = true;
    }
}

  componentDidMount() {
    this.props.startLoader();
    this.sidebarAnimation.toggle();
    this.fetchCapabilityModellingProjectData();
  }

  
  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false
        }
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true
        }
      });
    }
  }

  fetchDefaultMappingMatrixData()
  {
    /*console.log('CapabilityMappingID=',0);
    console.log('_a');
    console.log('BenchmarkProjectID=',this.state.selectedBenchmarkProjectID);
    console.log('DecompositionProjectID=',this.state.selectedDecompositionProjectID); */
    
    // capability-mapping/{CapabilityMappingID}/{BenchmarkProjectID}/{DecompositionProjectID}

    // CapabilityMappingID
    let capabilityMappingID = 0;

    this.setState({
      mappingMatrixData: []
    });

    if(this.state.versionClicked)
    {
      capabilityMappingID = this.state.CapabilityMappingID;
    }

    //this.props.startLoader();
    return this.apiServer
      .SendRequest({
        method: "GET",
        url: "/capability-mapping/"+capabilityMappingID+"/"+this.state.selectedBenchmarkProjectID+"/"+this.state.selectedDecompositionProjectID,
        data: ""
      })
      .then(result => {
        
        if (result.success === true) {
          //console.log(result.data.mapping.length);      
          if(result.data.mapping)
          {
            //console.log(result.data[0]);
            if(result.data[0].Version == 0)
            {
              //console.log(result.data[0].CapabilityMappingID);
              this.setState({
                CapabilityMappingID: result.data[0].CapabilityMappingID,   
                hasInprogressData:true,             
              });
            }
            
            let matrix = this.state.mappingMatrixData;
            let checkedCapabilityIdArr = [];

            result.data.mapping.forEach(elem => {
              matrix.push(elem.DecompositionProcessLevel1ID+'|'+elem.DomainID);
              checkedCapabilityIdArr.push(elem.DecompositionProcessLevel1ID);
            });

            // checkedCapabilityIdArr    
          let uniqueArray = checkedCapabilityIdArr.filter(function(item, pos) {
            return checkedCapabilityIdArr.indexOf(item) == pos;
          });
          //console.log(uniqueArray);

          let tempVDisp = '';
          if(result.data[0].Version == 0)
          {
            if(parseInt(result.data[0].basedOnVersionID) >0)
            {
              tempVDisp = ' based on version';
            }
          }

            this.setState({
              mappingMatrixData: [...matrix],
              checkedCapabilityIdArr: uniqueArray,
              latestVersionID: (result.data[0].Version == 0) ? parseInt(result.data[0].basedOnVersionID): result.data[0].Version,
              matrixDisplayLabel: (result.data[0].Version == 0) ? 'Change in progress': ''
            });
            //console.log('def--');
          }
          
          //this.props.stopLoader();
        }
      })
      .catch(error => {
        //this.props.stopLoader();
        responseMessage("error", "Sorry something went wrong. Please try again", "");
        console.log(error);
      });
  }


  fetchVersionListingData()
  {

    // capability-mapping-list/{BenchmarkProjectID}/{DecompositionProjectID}

    //this.props.startLoader();
    return this.apiServer
      .SendRequest({
        method: "GET",
        url: "/capability-mapping-list/"+this.state.selectedBenchmarkProjectID+"/"+this.state.selectedDecompositionProjectID,
        data: ""
      })
      .then(result => {
        
        if (result.success === true) {
          //console.log(result);     
          if(result.data.length > 0)
          {            
            let versionData = [...result.data];         
            
            // // latestVersionID:0, matrixDisplayLabel:'',
            let latestVersionID = 0;
            
            if(versionData.length > 0)
            {
              versionData.forEach(elem => {
                latestVersionID = elem.Version;              
              });
            }

            //alert('latest versin='+latestVersionID);
            this.setState({
              versionData: [...versionData]
            });
          } 
          
          //this.props.stopLoader();
        }
      })
      .catch(error => {
        //this.props.stopLoader();
        responseMessage("error", "Sorry something went wrong. Please try again", "");
        console.log(error);
      });
  }  

  componentDidUpdate(prevProps, prevState)
  {
    if(prevState.selectedBenchmarkProjectID !== this.state.selectedBenchmarkProjectID && this.state.selectedBenchmarkProjectID>0)
    {
      this.fetchVersionListingData();
      this.fetchDefaultMappingMatrixData();
      
    }    
    
}
  

  handleVersionChange = (e,val) => {
     //alert("VALUE");
     //tempCapabilityMappingID
     //console.log(val);
     this.setState({
      tempCapabilityMappingID: val.CapabilityMappingID,
      templatestVersionID: parseInt(val.Version),
      selectButtonDisableStatus:false
    });
     
  }  

  selectVersion = (e) =>
  {
    let versionMsg = 'Do you want to change capability mapping version?';

    if(this.state.hasInprogressData)
    {
      versionMsg = 'Changes will be lost. Do you want to change capability mapping version?';

    }else if(this.state.mappingModificationStarted)
    {
      versionMsg = 'Changes made since last save will be lost. Do you want to change capability mapping version?';
    }
    e.preventDefault();
    confirmAlert({
      title: 'Confirmation',
      message: versionMsg,
      buttons: [
          {
              label: 'Yes',
              onClick: () => {

          //alert('ok');
          this.props.startLoader();
          this.mappingMatrixData = [];
          //console.log("VALUE",val);
          let tempCapabilityMappingID = this.state.tempCapabilityMappingID;
          let templatestVersionID = this.state.templatestVersionID;
          this.setState({
          finalizeMapping: false,
          mappingModificationStarted:false,
          matrixDisplayLabel: '',
          latestVersionID:templatestVersionID,
          templatestVersionID:0,
          saveProgressButtonText: "Save Progress",
          versionClicked:true,
          updateVersion:false,
          mappingMatrixData:[],
          CapabilityMappingID: tempCapabilityMappingID,
          tempCapabilityMappingID:0
          }, () => {
              this.fetchDefaultMappingMatrixData();
              let selfObj = this;
                setTimeout(function(){              
                  selfObj.props.stopLoader();
              },1000);
          });     
    
      }
        },
        {
            label: 'No',
            onClick: () => {
              //alert(this.state.templatestVersionID);
            // capability_mapping_radio_
            if(this.state.templatestVersionID > 0)
            {
              //alert(this.state.templatestVersionID);
              document.getElementById('capability_mapping_radio_'+this.state.templatestVersionID).checked = false;
            }

            if(this.state.latestVersionID > 0 && this.state.versionClicked)
            {
              document.getElementById('capability_mapping_radio_'+this.state.latestVersionID).checked = true;
            }              
              
            }
        }
      ]
      });     

  }

  fetchCapabilityModellingProjectData() {    
    return this.apiServer
      .SendRequest({
        method: "GET",
        url: "/capability-mapping-modeling-project",
        data: ""
      })
      .then(result => {
        //console.log(result);
        if (result.success === true) {
          if(result.data.length > 0)
          {
            this.capabilityModellingProjectData = [...result.data];
            /*this.capabilityModellingProjectData.forEach(elem => {
              elem.checked = false;
            });*/
            this.setState({
              capabilityModellingProjectData: [...this.capabilityModellingProjectData]
            });
          }
          
        }
        this.props.stopLoader();
      })
      .catch(error => {
        this.props.stopLoader();
        responseMessage("error", "Sorry something went wrong. Please try again", "");
        console.log(error);
      });
  }

  getDomainNames(benchmarkProjectID) {
    //this.props.startLoader();
    return this.apiServer
      .SendRequest({
        method: "GET",
        url: "/get_user_domains_bm_project/"+benchmarkProjectID,
        data: ""
      })
      .then(result => {
        //console.log(result);
        if (result.success === true) {
          if(result.data.length > 0)
          {
            this.userDomainsData = [...result.data];
            /*this.capabilityModellingProjectData.forEach(elem => {
              elem.checked = false;
            });*/
            this.setState({
              userDomainsData: [...this.userDomainsData]
            });
            //this.props.stopLoader();

          }else{
            //selectedCapabilityMappingName
            this.setState({
              selectedCapabilityMappingName: ''
            });
            responseMessage("error", "No domain found", "");
          }
          
        }
      })
      .catch(error => {
        //this.props.stopLoader();
        responseMessage("error", "Sorry something went wrong. Please try again", "");
        console.log(error);
      });
  }

  getCapabilityNames() {
    //this.props.startLoader();
    return this.apiServer
      .SendRequest({
        method: "GET",
        url: "/capability-mapping-modeling-project-capability/"+this.state.selectedDecompositionProjectID,
        data: ""
      })
      .then(result => {
        //console.log(result);
        if (result.success === true) {
          if(result.data.length > 0)
          {
            this.capabilityData = [...result.data];
            let localCapabilityArr = [];

            this.capabilityData.forEach(elem => {
              localCapabilityArr.push(elem.DecompositionProcessLevel1ID);              
            });

            this.setState({
              capabilityData: [...this.capabilityData],
              allCapabilityIdArr: localCapabilityArr
            });

            //allCapabilityIdArr
            //this.props.stopLoader();

          }else{
            this.setState({
              selectedCapabilityMappingName: ''
            });
            responseMessage("error", "No capability found", "");
          }
          
        }
      })
      .catch(error => {
        //this.props.stopLoader();
        responseMessage("error", "Sorry something went wrong. Please try again", "");
        console.log(error);
      });
  }

  LinkedBenchmarkingProject(){
    //alert(this.state.selectedDecompositionProjectID);    
    /* let selectedCapabilityModellingProject = [];
    this.state.capabilityModellingProjectData.forEach(elem => {
      if (elem.BenchmarkProjectID == this.state.selectedDecompositionProjectID) {
         if(elem.Status === 'Linked')
         {
          selectedCapabilityModellingProject.push(elem);
          this.getDomainNames(elem.BenchmarkProjectID);
         }else{
          responseMessage("error", "Project not linked", "");
         }
      }
      this.setState({ selectedCapabilityModellingProject: selectedCapabilityModellingProject, userDomainsData:[] });
    }); */
    this.props.startLoader();
    return this.apiServer
      .SendRequest({
        method: "GET",
        url: "/capability-mapping-linked-benchmark-project/"+this.state.selectedDecompositionProjectID,
        data: ""
      })
      .then(result => {
        //console.log(result);
        if (result.success === true) {
          if(result.data.length == 0)
          {
            responseMessage("error", "Project not linked with Benchmarking project", "");
            this.props.stopLoader();
          }else{
            let selectedDecompositionProjectName = '';
            this.state.capabilityModellingProjectData.forEach(elem => {
              if (elem.DecompositionProjectID == this.state.selectedDecompositionProjectID) {
                selectedDecompositionProjectName = elem.ProjectName;
              }
            }); 

            this.setState({ selectedBenchmarkProjectID: result.data.BenchmarkProjectID, selectedBenchmarkProjectName: result.data.BenchmarkProjectName, selectedDecompositionProjectName: selectedDecompositionProjectName, selectedCapabilityMappingName:result.data.BenchmarkProjectName+'-'+selectedDecompositionProjectName, mappingModificationStarted:false});
            //alert('link found');
            //this.props.stopLoader();
            this.getDomainNames(result.data.BenchmarkProjectID);
            this.getCapabilityNames();
            let selfObj = this;
            setTimeout(function(){              
              selfObj.props.stopLoader();
          },7000);
          }
          
        }
      })
      .catch(error => {
        //this.props.stopLoader();
        responseMessage("error", "Sorry something went wrong. Please try again", "");
        console.log(error);
      });
  }

  finalizeMapping(event) {

    //console.log(this.state.allCapabilityIdArr);
    //console.log(this.state.checkedCapabilityIdArr);    
    
    // if(window.confirm('Do you want to finalize this mapping ?')){}

      if (event.target.checked) {
        if(this.state.checkedCapabilityIdArr.length < this.state.allCapabilityIdArr.length)
        {
          responseMessage("error", "Each capability needs to be mapped with at least one domain", "");
          return false;
        }else{
          //responseMessage("success", "All capability selected", "");
        }
        this.setState({ finalizeMapping: true, saveProgressButtonText:"Create new version" });
        
      } else {
        this.setState({ finalizeMapping: false, saveProgressButtonText:"Save Progress" });
      }    
    
  }

  async saveProgress(e){
    e.preventDefault();
    this.props.startLoader();
    let mappingMatrixString = this.state.mappingMatrixData.toString();
    let data = {
        "BenchmarkProjectID":parseInt(this.state.selectedBenchmarkProjectID),
        "DecompositionProjectID":parseInt(this.state.selectedDecompositionProjectID),
        "CapabiltyMappingName":this.state.selectedCapabilityMappingName,
        "finalizeMapping":this.state.finalizeMapping,
        "basedOnVersionID":this.state.latestVersionID,
        "CapabilityMappingID":this.state.CapabilityMappingID,
        'mapping':mappingMatrixString,
        "update":this.state.updateVersion
    }
    let selfObj = this;
    await axios.post(config.laravelBaseUrl+'capability-mapping-save', data, {
        headers: {
            "authorization": "Bearer " + sessionStorage.getItem("userToken")
        }
    }).then(function(response){
      //CapabilityMappingID
      //selectedVersion           
      //console.log('saved--'); 
      selfObj.props.stopLoader();        
        responseMessage("success", response.data.message, ""); 

        selfObj.setState({
          CapabilityMappingID: 0, 
          matrixDisplayLabel: '',
          saveProgressButtonText:"Save Progress", 
          finalizeMapping: false, 
          hasInprogressData:false,
          mappingModificationStarted:false 
         }, () => {selfObj.fetchVersionListingData(); selfObj.fetchDefaultMappingMatrixData()});         

         if(selfObj.state.versionData.length > 0)
         {
          document.querySelector('input[name="selectedVersion"]:checked').checked = false;
         }

        /*selfObj.resetAfterSave();       
        selfObj.fetchDefaultMappingMatrixData();
        selfObj.fetchVersionListingData(); */

    }).catch(function(error) {
      selfObj.props.stopLoader();
      console.log(error);
        //responseMessage("Error", "Something Went Wrong!7", "");
        
    });
  

}

resetAfterSave()
{
  this.setState({ CapabilityMappingID: 0, saveProgressButtonText:"Save Progress", hasInprogressData:false, finalizeMapping: false, mappingModificationStarted:false });
  document.querySelector('input[name="selectedVersion"]:checked').checked = false;
}

  saveMappingProgress(mappingId)
  {
    //console.log(this.state.mappingMatrixData);
    //mappingId = DecompositionProcessLevel1ID+'-'+DomainID

    //alert(mappingId);
    this.mappingMatrixData = this.state.mappingMatrixData;
    const arr = this.mappingMatrixData; //example array
    const newId=mappingId;                 //new id 

    if(!arr.includes(newId)){          //checking weather array contain the id
        arr.push(newId);               //adding to array because value doesnt exists
    }else{
        arr.splice(arr.indexOf(newId), 1);  //deleting
    }
    this.mappingMatrixData = arr;
    //console.log(arr);

    // checkedCapabilityIdArr
    let checkedCapabilityIdArr = [];
    this.mappingMatrixData.forEach(elem => {      
      const matrixArray = elem.split("|");
      checkedCapabilityIdArr.push(matrixArray[0]);
    }); 
    //console.log(checkedCapabilityIdArr);

    let uniqueArray = checkedCapabilityIdArr.filter(function(item, pos) {
      return checkedCapabilityIdArr.indexOf(item) == pos;
    });
    //console.log(uniqueArray);

    this.setState({
      mappingMatrixData: [...this.mappingMatrixData],
      checkedCapabilityIdArr: uniqueArray,
      mappingModificationStarted:true
    });

  }

  cancelProgress(e)
  {
    e.preventDefault();
    let versionMsg = 'Changes made since last save will be lost. Do you want to cancel modification?';
    
    confirmAlert({
      title: 'Confirmation',
      message: versionMsg,
      buttons: [
          {
              label: 'Yes',
              onClick: () => {

          //alert('ok');
          this.props.startLoader();
          this.mappingMatrixData = [];
          //console.log("VALUE",val);
          this.setState({
            mappingModificationStarted:false,
            finalizeMapping:false,
            saveProgressButtonText: "Save Progress"
          }, () => {
              this.fetchDefaultMappingMatrixData();
              let selfObj = this;
                setTimeout(function(){              
                  selfObj.props.stopLoader();
              },1000);
          });     
    
      }
        },
        {
            label: 'No',
            onClick: () => {

            //
            }
        }
      ]
      });  
  }




  render() {
    
    return [
      <DashboardHeader></DashboardHeader>,
      <div id="wrapper">
        <Prompt
            when={this.state.mappingModificationStarted}
            message={() =>
            `Unsaved changes. Changes made since last save will be lost. Do you want to continue?`
            }
        />
        <DashboardSidebar></DashboardSidebar>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <ol className="breadcrumb dashbread">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item active">Capability Mapping</li>
              <li className="breadcrumb-menu d-md-down-none ml-auto">
              {<img src={ require('./../../common/images/diva-icon.png') } className="logo-img" alt="Logo" /> }
                  <a className="btn powered p-0" href="#">
                    <i className="icon-graph"></i> &nbsp;
                    <i>Powered by Amploglobal</i>
                  </a>
              </li>
            </ol>
            
              <div className="capabilityMappingWrapper mt-4">         
                <div className="capability-mapping-section">
                 <div className="inner-section">
                  <Row className="business-decomposition-sec">
                    <Col xl="12" className="pr-0">
                        <div className="model-list">
                        <Label className="model-label">Select Capability Modeling Project</Label>                        
                        </div>
                    </Col>
                    <Col xl="12" className="pr-0">
                        <div className="model-list">
                        <select className="form-control inner-width" onChange={(e)=>this.setState({
                          latestVersionID:0, 
                          matrixDisplayLabel:'', 
                          selectedDecompositionProjectID: e.target.value, 
                          selectedDecompositionProjectName:'', 
                          selectedCapabilityMappingName:'', 
                          selectedBenchmarkProjectID:'', 
                          selectedBenchmarkProjectName:'', 
                          benchmarkProjectData: [], 
                          userDomainsData:[], 
                          capabilityData:[], 
                          versionData:[], 
                          tempCapabilityMappingID:0,
                          CapabilityMappingID: 0,
                          hasInprogressData:false,
                          versionClicked:false,  
                          updateVersion:false, 
                          selectButtonDisableStatus:true
                          }, () => {this.LinkedBenchmarkingProject();})}>
                          <option value="">Select Project</option>
                          {this.state.capabilityModellingProjectData.map(rel=>{
                                return <option key={`rel_${rel.DecompositionProjectID}`} value={rel.DecompositionProjectID}>{rel.ProjectName}</option>
                              })}      
                        </select>
                        
                        </div>
                    </Col>                    
                  </Row>
                 </div>
                 <div className="inner-section">
                  <Row className="business-decomposition-sec">
                    <Col xl="12" className="pr-0">
                        <div className="model-list">
                        <Label className="model-label">Linked Benchmarking Project</Label>                        
                        </div>
                    </Col>
                    <Col xl="12" className="pr-0">
                        <div className="model-list">
                        <input
                            type="text"
                            readOnly
                            name=""
                            placeholder=""
                            className="form-control inner-width"
                            value={this.state.selectedBenchmarkProjectName}
                          />                        
                        </div>
                    </Col>
                  </Row>
                 </div>
                 <div className="inner-section">
                  <Row className="business-decomposition-sec">
                    <Col xl="12" className="pr-0">
                        <div className="model-list">
                        <Label className="model-label">Capability Mapping</Label>                        
                        </div>
                    </Col>
                    <Col xl="12" className="pr-0">
                        <div className="model-list">
                        <input
                            type="text"
                            readOnly
                            name=""
                            placeholder=""
                            className="form-control inner-width"
                            value={this.state.selectedCapabilityMappingName}
                          />
                        
                        </div>
                    </Col>
                  </Row>
                 </div>
                </div>                 
                {                  
                  this.state.userDomainsData.length>0 &&
                
                <div className="domain-name-section">                  
                  <div className="domain-mapping-panel">
                  {(this.state.latestVersionID > 0 || this.state.matrixDisplayLabel != '') ?
                  <div className="capability-mapping-section">
                  <div className="col-sm-12 pl-0">
                    <span class="badge badge-info badge-counter beta-b" style={{padding:"8px", fontSize:"13px"}}>
                      {this.state.matrixDisplayLabel != '' ? this.state.matrixDisplayLabel+' ' : ''  }
                      {this.state.latestVersionID > 0 ? ' version - '+this.state.latestVersionID+'.0' : ' '  }                      
                    </span>
                  </div>
                </div>
                :null }
                  <div className="domain-mapping-section">
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th style={{width:"25%", left:"0", zIndex:"999"}}></th>
                    {this.state.userDomainsData.map(rel=>{
                      return(
                        <th>{rel.DomainName}</th>
                      ) 
                    })} 
                    </tr>
                    </thead>
                    <tbody>
                                    
                    {this.state.capabilityData.map((relCapability, ind)=>{
                      return(
                        <tr key={`capability_mapping_${ind}`}> 
                        <td className="stickycol"><strong>{relCapability.ProcessLevel1Title}</strong></td>
                        {this.state.userDomainsData.map(relDomain=>{
                            return(
                              <td className="text-center">
                                {/* <i class="far fa-check-circle"></i> */}
                                <input type="checkbox"
                                name="capability_mapping"                                
                                checked={(this.state.mappingMatrixData.includes(relCapability.DecompositionProcessLevel1ID+'|'+relDomain.DomainID)) ? true : false }
                                value={relCapability.DecompositionProcessLevel1ID+'-'+relDomain.DomainID}
                                 title={` ${relCapability.ProcessLevel1Title} > ${relDomain.DomainName}`}
                                onChange={(e) => this.saveMappingProgress(relCapability.DecompositionProcessLevel1ID+'|'+relDomain.DomainID)}
                            />{" "}{/*{relCapability.ProcessLevel1Name}{" > "}{relDomain.DomainName}*/}
                            </td>
                            ) 
                          })} 
                        </tr>
                      ) 
                    })} 
                    </tbody>
                    </table> 
                  </div>
                  <div className="d-flex save-progress-panel">
                  <div style={{width:"33%"}}></div>
                  <div style={{width:"33%"}}>
                    <input type="checkbox" 
                    title={`Finalize Mapping`} 
                    onClick={this.finalizeMapping.bind(this)}
                    checked={this.state.finalizeMapping}
                    />{" "}Finalize Mapping</div>
                  <div style={{width:"33%",display:"flex", justifyContent:"flex-end", marginRight:"15px",marginTop:"10px"}}>
                  <button style={{marginRight:"10px", padding:7, fontSize:"12px"}} disabled={this.state.mappingModificationStarted ? false:true} onClick={(e) => this.cancelProgress(e)} type="button" className="btn btn btn-danger">Cancel</button>
                    <button style={{padding:7, fontSize:"12px"}} disabled={(this.state.mappingModificationStarted || this.state.finalizeMapping) ? false:true} onClick={(e) => this.saveProgress(e)} type="button" className="btn btn btn-primary">{this.state.saveProgressButtonText}</button>
                  </div>  
                  </div>                                     
                  </div>
                  <div className="domain-mapping-version-section">
                  <Label className="model-label mb-10">Capability Mapping Revision List</Label> 
                  <div className="versionWrapper">
                  {this.state.versionData ?
                    this.state.versionData.map((val,ind) => {                                   
                      return ( 
                          
                          <div className={`d-flex version-style ${this.state.CapabilityMappingID === val.CapabilityMappingID ? "versionActive" : ""}`} key={'version-'+ind}>                                                                                                   
                            <input type="radio" onChange={(e) => this.handleVersionChange(e,val)} name="selectedVersion" id={`capability_mapping_radio_${parseInt(val.Version)}`} />
                            <p className="date-txt justify-content-between ml-10"><span className="fontbold-">{ val.CapabiltyMappingName }{" - "}{val.Version}</span></p>                            
                              
                          </div> 
                          
                      )
                    })
                    :null
                  }  
                  </div>
                  {this.state.versionData.length > 0 ?
                  <div style={{display:"flex", justifyContent:"flex-end", marginRight:"15px",marginTop:"10px"}}>
                    <button style={{padding:7, fontSize:"12px"}} onClick={(e) => this.selectVersion(e)} disabled={this.state.selectButtonDisableStatus} type="button" className="btn btn btn-primary">Select</button>
                  </div>                    
                    :null
                  }  
                  </div>                
                </div>
                }
              </div>

                
              </div>
          </div>
        </div>,
      <AsideComponent></AsideComponent>,
      <FooterComponent></FooterComponent>
    ];
  }
}

export default connect(null, { startLoader, stopLoader })(CapabilityMapping);