import React , { Component } from 'react';
import DashboardHeader from "../../includes/dashboardHeader/DashboardHeader";
import FooterComponent from "../../includes/dashboardFooter/FooterComponent";
import AsideComponent from "../../includes/asideComponent/AsideComponent";
import BalancedScorecardBreadcrumb from '../balancedScorecardBreadcrumb';
import BalancedScorecardGoalSetting from '../balancedScorecardGoalSetting';
import BalancedScorecardObjectivesTarget from '../balancedScorecardObjectivesTargets';
import {BalancedScorecardWrapper} from '../styling/balancedScorecard';
import {jsPlumb} from 'jsplumb'; 
import axios from 'axios';
import moment from 'moment';
import { startLoader, stopLoader } from '../../../actions/loaderActions';
import { connect } from 'react-redux';
import CacheStorage from '../../../utils/CacheStorage';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery';
import {Canvg} from 'canvg';
import htmlToImage from 'html-to-image';
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import {responseMessage} from '../../../utils/alert';

const config = require('../../../config');

class BalancedScorecardReport extends Component{
    constructor(props){ 
        super(props);
    }
    state = {
        goalSetting : [{
            "id": 0,
            "title": "Vision",
            "description": "Double Tap to Edit."
        },{
            "id": 0,
            "title": "Purpose",
            "description": []
        },{
            "id": 0,
            "title": "StrategicPriorities",
            "description": []
        },{
            "id": 0,
            "title": "StrategicResults",
            "description": []
        }],
        categoryRows : [],
        bscId : this.props.match.params.bscId?this.props.match.params.bscId:0,
        fromDate : this.props.match.params.fromDate?this.props.match.params.fromDate:"",
        toDate : this.props.match.params.toDate?this.props.match.params.toDate:"",
        bscName : "",
        connections : [],
        withActuals : (this.props.match.params.fromDate && this.props.match.params.toDate)?true:false,
        selectedToDate : this.props.match.params.toDate?new Date(this.props.match.params.toDate):"",
        selectedFromDate : this.props.match.params.fromDate?new Date(this.props.match.params.fromDate):"",
        BalancedScorecardGoalSettingModal:false,
        showGrantTaskPanel: false,
        showDependencyPanel: false,
        chartLegend:[],
        categoryNameKpiNameControlLevers:[
            {
                "CategoryId": "15",
                "title": "Financial",
                "DependencyId": "1",
                "Kpi": [
                    {
                        "KpiId": "156",
                        "KpiName": "ISO50001 Certification",
                        "Target": "$65.00 Per Month",
                        "DependencyId": "1.1",
                        "ControlLevers": [
                            {
                                "KPIControlLeversID": "821",
                                "ControlLeversTitle": "ISO50001 Leadership",
                                "ControlLeverTarget": null,
                                "CheckStatus": "1",
                                "DependencyId": "1.1.1",
                                "DisplayDependency":""
                            },
                            {
                                "KPIControlLeversID": "829",
                                "ControlLeversTitle": "ISO50001 Planning",
                                "ControlLeverTarget": null,
                                "CheckStatus": "1",
                                "DependencyId": "1.1.2",
                                "DisplayDependency":""
                            }
                        ]
                    }
                ]
            },
            {
                "CategoryId": "30",
                "title": "Customer",
                "DependencyId": "2",
                "Kpi": [
                    {
                        "KpiId": "159",
                        "KpiName": "Achieve 10% Increase in Revenue",
                        "Target": "10.00% Per Quarter",
                        "DependencyId": "2.1",
                        "ControlLevers": [
                            {
                                "KPIControlLeversID": "822",
                                "ControlLeversTitle": "Performance Evaluation",
                                "ControlLeverTarget": "0.00 Per Quarter",
                                "CheckStatus": "1",
                                "DependencyId": "2.1.1",
                                "DisplayDependency":"1.1.1"
                            },
                            {
                                "KPIControlLeversID": "823",
                                "ControlLeversTitle": "Faucet Aerator Replacement  Program",
                                "ControlLeverTarget": "0.00 Per Quarter",
                                "CheckStatus": "1",
                                "DependencyId": "2.1.2",
                                "DisplayDependency":""
                            }
                        ]
                    }
                ]
            },
            {
                "CategoryId": "32",
                "title": "Operational",
                "DependencyId": "3",
                "Kpi": [
                    {
                        "KpiId": "155",
                        "KpiName": "Wadhwani Foundation - Profitability & Efficiency",
                        "Target": "$43.00 Per Month",
                        "DependencyId": "3.1",
                        "ControlLevers": [
                            {
                                "KPIControlLeversID": "824",
                                "ControlLeversTitle": "Gross Profit",
                                "ControlLeverTarget": "$54.00 Per ",
                                "CheckStatus": "1",
                                "DependencyId": "3.1.1",
                                "DisplayDependency":"1.1.1,2.1.1"
                            },
                            {
                                "KPIControlLeversID": "825",
                                "ControlLeversTitle": "Gross Margin",
                                "ControlLeverTarget": "$54.00 Per ",
                                "CheckStatus": "1",
                                "DependencyId": "3.1.2",
                                "DisplayDependency":""
                            },
                            {
                                "KPIControlLeversID": "826",
                                "ControlLeversTitle": "Operating Profit",
                                "ControlLeverTarget": "$54.00 Per ",
                                "CheckStatus": "1",
                                "DependencyId": "3.1.3",
                                "DisplayDependency":""
                            }
                        ]
                    },
                    {
                        "KpiId": "155",
                        "KpiName": "Market Intelligence - Wadhwani Foundation",
                        "Target": "$46.00 Per Month",
                        "DependencyId": "3.2",
                        "ControlLevers": [
                            {
                                "KPIControlLeversID": "827",
                                "ControlLeversTitle": "Market Size by Industry Segments",
                                "ControlLeverTarget": "$54.00 Per ",
                                "CheckStatus": "1",
                                "DependencyId": "3.2.1",
                                "DisplayDependency":""
                            },
                            {
                                "KPIControlLeversID": "828",
                                "ControlLeversTitle": "Market Size by Industry",
                                "ControlLeverTarget": "0.00% Per Month",
                                "CheckStatus": "1",
                                "DependencyId": "3.2.2",
                                "DisplayDependency":""
                            }
                        ]
                    }
                ]
            }
        ],
        controlLeversDependencyBridge:[],
        gantttasks:[
            {
                id: "1.1.1",
                name: "Redesign website",
                start: new Date('2023-12-01'),
                end: new Date('2023-12-31'),
                progress: 10
              }
          ],
        controlLeversTitleModal:'',
        controlLeverTargetModal:'',
        controlLeverExpectedGainModal:'',
        controlLeverUnitOfMeasurementModal:'',
        showcontrolLeversModal:false
    }
    componentDidMount = () => {        
        if(this.state.bscId !== 0){
            this.fetchBsc()
            this.getCategoryNameKpiNameControlLevers()
        }
    }

    handleGrantTaskClick = (task) => {
        console.log("On Click event Id:" + task.id);
    console.log("On Click kpiControlLeversID:" + task.kpiControlLeversID);
    }

    getStartEndDateForProject = (tasks, projectId) => {
        const projectTasks = tasks.filter((t) => t.project === projectId);
        let start = projectTasks[0].start;
        let end = projectTasks[0].end;
      
        for (let i = 0; i < projectTasks.length; i++) {
          const task = projectTasks[i];
          if (start.getTime() > task.start.getTime()) {
            start = task.start;
          }
          if (end.getTime() < task.end.getTime()) {
            end = task.end;
          }
        }
        return [start, end];
      };
    
    handleTaskChange = (task) => {        
        console.log("On date change Id:" + task.id);
        let newTasks = this.state.gantttasks.map((t) => (t.id === task.id ? task : t));
        if (task.project) {
          const [start, end] = this.getStartEndDateForProject(newTasks, task.project);
          const project =
            newTasks[newTasks.findIndex((t) => t.id === task.project)];
          if (
            project.start.getTime() !== start.getTime() ||
            project.end.getTime() !== end.getTime()
          ) {
            const changedProject = { ...project, start, end };
            newTasks = newTasks.map((t) =>
              t.id === task.project ? changedProject : t
            );
          }
        }
    
        this.setState({
            gantttasks : newTasks
        })
      };

generateGranttTaskChart =()=>{

    let categoryNameKpiNameControlLevers = this.state.categoryNameKpiNameControlLevers;

    let hasSubKPI = false;
    
    let checkDependencyOccuranceArr = [];
    categoryNameKpiNameControlLevers.map((row, rowInd)=>{
        row.Kpi.map((kpi,ind)=>{
            kpi.ControlLevers.map((controlLever,controlLeverInd)=>{
               
                if(controlLever.DisplayDependency.length >1)
                {
                    let answ = controlLever.DisplayDependency.split(',');
                    answ.forEach(function(obj){
                        checkDependencyOccuranceArr.push(obj);
                    });
                    
                }
            });
        });    
    });
    //console.log('---hb-dep');
    //console.log(checkDependencyOccuranceArr);

    const occurrences = checkDependencyOccuranceArr.reduce(function (acc, curr) {
        return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
      }, {});

      //console.log(occurrences);  

    let grantTastArr = [];

    const chartCanvas = [
        '#ffdf83',
        '#fbb5b5',
        '#b2e1ef',
        '#cdcd04',
        '#aa7e74',
        '#a2f788',
        '#e5c62d',
        '#fffbbc',
        '#efad6b',
        '#ffff4d'
      ];

      let colorIdx = 0;
    
      let chartLegend = [];
   
    let datecounter = 0;
    categoryNameKpiNameControlLevers.map((row, rowInd)=>{
        colorIdx = (colorIdx + 1) % chartCanvas.length;
        chartLegend.push({'legendName':row.title,'legendColor':chartCanvas[colorIdx]});
        row.Kpi.map((kpi,ind)=>{
            kpi.ControlLevers.map((controlLever,controlLeverInd)=>{

                hasSubKPI = true;
               
                let grantTaskDependencyArray = [];

                let progressChart = 1;

                let depOccurence = 0;

                if(occurrences[controlLever.DependencyId])
                {
                    //console.log(occurrences[controlLever.DependencyId]);
                    depOccurence = depOccurence+occurrences[controlLever.DependencyId];
                }
                 //console.log(depOccurence);   

                if(controlLever.DisplayDependency.length >1)
                {
                    grantTaskDependencyArray = controlLever.DisplayDependency.split(',');                    
                    
                }


                if(controlLever.DisplayDependency.length >1)
                {
                    progressChart = 100;

                }                
                

                if(controlLever.DisplayDependency.length >1)
                {
                  var newStartDate = new Date(new Date().getTime()+((Math.floor(Math.random() * 15))*24*60*60*1000));
                    
                }else{

                    var newStartDate = new Date(new Date().getTime()+((Math.floor(Math.random() * 10))*24*60*60*1000));  

                }
                
                    grantTastArr.push({ 
                    id: controlLever.DependencyId,
                    kpiControlLeversID:controlLever.KPIControlLeversID,
                    name: controlLever.ControlLeversTitle,                    
                    start: newStartDate,
                    end: new Date(new Date().getTime()+((Math.floor(Math.random() * 25) + 20)*24*60*60*1000)),
                    progress: 100,
                    dependencies: grantTaskDependencyArray,
                    styles: { fontColor:"#000000", progressColor: chartCanvas[colorIdx], progressSelectedColor: chartCanvas[colorIdx], BackgroundColor: chartCanvas[colorIdx] }
                });
                datecounter++;
            });
        });    
    });
    if(hasSubKPI)
    {
        //console.log(grantTastArr);
        this.setState({
            showGrantTaskPanel:true,
            gantttasks : grantTastArr,
            chartLegend: chartLegend
        })
    }
    

}

 
getCategoryNameKpiNameControlLevers = async () => {
   
    let res_data =  [];
    
        let bsc = await axios.get(config.laravelBaseUrl + "getGlobalBusinessInitiative/" +this.state.bscId,{
            headers: {
            "authorization": "Bearer " + CacheStorage.getItem("userToken")
            }
        }).then(result => {
            //console.log(result.data.data);            
            res_data =  result.data.data;

            this.setState({
                categoryNameKpiNameControlLevers : res_data,
                showDependencyPanel: true,
                showCardKpi:true,
            },()=>{this.generateGranttTaskChart()})

    //alert('ok'+id);
    
            
          }).catch(error => {
            
            /* this.setState({
                categoryRows : [],
                showGrantTaskPanel: false,
                showDependencyPanel: false,
                categoryNameKpiNameControlLevers:[],
                controlLeversDependencyBridge:[],
                gantttasks:[
                    {
                        id: "1.1.1",
                        name: "Redesign website",
                        start: new Date('2023-12-01'),
                        end: new Date('2023-12-31'),
                        progress: 10
                    }
                ],
                controlLeversTitleModal:'',
                controlLeverTargetModal:'',
                controlLeverExpectedGainModal:'',
                controlLeverUnitOfMeasurementModal:'',
                showcontrolLeversModal:false
            }) */
            responseMessage("error", "Sorry something went wrong. Please try again", "");
            console.log(error);
          });
        
 
    
}
    fetchBsc = async () => {
        let bsc = {};
        if(this.state.fromDate === '' && this.state.toDate === ''){
            bsc = await axios.post(config.laravelBaseUrl + "getBSCReportKpi",{
                BalanceScoreCardId : this.state.bscId,
                FromDate : this.state.fromDate,
                ToDate : this.state.toDate
            },{
                headers: {
                "authorization": "Bearer " + CacheStorage.getItem("userToken")
                }
            });
        }else{
            bsc = await axios.post(config.laravelBaseUrl + "getBscReportKpiActual",{
                BalanceScoreCardId : this.state.bscId,
                FromDate : this.state.fromDate,
                ToDate : this.state.toDate
            },{
                headers: {
                "authorization": "Bearer " + CacheStorage.getItem("userToken")
                }
            });
        } 
        bsc = bsc.data.data;
        this.setState({
            bscName : bsc.title,
            goalSetting : bsc.goalsetting,
            categoryRows : bsc.strategicobjective
        },()=>{
            
        })
    }
    

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.match.params.fromDate != this.state.fromDate || nextProps.match.params.toDate != this.state.toDate || nextProps.match.params.bscId != this.state.bscId){
            this.setState({
                fromDate : nextProps.match.params.fromDate ,
                toDate : nextProps.match.params.toDate,
                bscId : nextProps.match.params.bscId
            },()=>{
                this.fetchBsc()
                this.getCategoryNameKpiNameControlLevers()
            })
        }
    }

    fromDateChangeHandler = (date) => {
        this.setState({
            selectedFromDate : date
        })
    }

    toDateChangeHandler = (date) => {
        this.setState({
            selectedToDate : date
        })
    }

    runReportHandler = () => {
        if(this.state.selectedFromDate && this.state.selectedToDate){
            this.props.history.push('/balanced-scorecard/report/'+this.state.bscId+"/"+moment(this.state.selectedFromDate).format("YYYY-MM-DD")+"/"+moment(this.state.selectedToDate).format("YYYY-MM-DD"))
        }
    }

    printMappingImage=()=>{
        var thisObj = this;
        var imgName = this.state.bscName;
        imgName = imgName.replace(/\s+/g, '-').toLowerCase();
        this.props.startLoader();
        domtoimage.toJpeg(document.getElementById('toCanvas'), { quality: 0.95 })
    .then(function (dataUrl) {
        //var link = document.createElement('a');        
        //link.download = imgName+'.jpeg';
        //link.href = dataUrl;
        //link.click();

        var imgWidth = document.getElementById('toCanvas').clientWidth;
        var imgHeight = document.getElementById('toCanvas').clientHeight;
                
        const pdf = new jsPDF('l','mm',[imgWidth,imgHeight]);
        pdf.addImage(dataUrl, 'PNG', 1, 1, imgWidth, imgHeight);
        //pdf.addPage();
        pdf.save(imgName+'.pdf');

        thisObj.props.stopLoader();
    });
    }

    downloadpdfReport = () => {
var targetElem = document.getElementById('toCanvas')
        
         domtoimage.toPng(document.getElementById('toCanvas'))
         .then(function (dataUrl) {
             
            const pdf = new jsPDF('l','mm',[1370,800]);
             pdf.addImage(dataUrl, 'PNG', 10, 10, 439.83333333, 251.66666667);
             pdf.addPage()
             html2canvas(document.getElementById("canvas")).then(canvas => {
                // document.body.appendChild(canvas);
                // doc.addPage();
                var imgData = canvas.toDataURL(
                    'image/png');              
              
                pdf.addImage(imgData, 'PNG', 10, 10,439.83333333,251.66666667);
                pdf.save("download.pdf");
               
            });  
            //  pdf.save('download.pdf')
             //   
         })
         .catch(function (error) {
             console.error('oops, something went wrong!', error);
         });
       
        
                
       
      }
    render(){
        return(
            <>
                <DashboardHeader />
                <BalancedScorecardWrapper id="wrapper">
                    {/* <!-- Content Wrapper --> */}
                    <div id="content-wrapper" className="d-flex flex-column">
                        {/* <!-- Main Content --> */}
                        <div id="content">
                            <BalancedScorecardBreadcrumb />
                            {/* <!-- Begin Page Content --> */}
                            <div className="container-fluid container-dashboard">
                                <div className="row">
                                    <div className="dt-screen-main" style={{overflow:"hidden"}}>
                                        {/* <!-- Start Body Content --> */}
                                        <div className="dt-content-wrapper">
                                            <div className="content-wraper">
                                            <div className="container-fluid">
                                                {/* <!-- Balanced Scorecard Section --> */}
                                                    <div className="row balanced-version">
                                                    <div className="col-sm-12 col-md-12 col-lg-9 d-md-flex align-items-center pt-3">
                                                        <h2 style={{width:"35%"}}>{this.state.bscName}</h2>
                                                        {/* <input 
                                                        style={{width:"35%"}}
                                                        type="text" 
                                                        readOnly
                                                        value={this.state.bscName}
                                                        className="form-control" 
                                                        placeholder="Global Business Initiative Name" 
                                                        /> */}
                                                        <div className='d-flex pl-3'>
                                                            {this.state.fromDate ? <><span><b>From Date :</b></span> <span>{moment(this.state.fromDate).format('DD/MM/YYYY')}</span></> : null }
                                                            {this.state.toDate ? <><span className="ml-2"><b>To Date :</b></span> <span>{moment(this.state.toDate).format('DD/MM/YYYY')}</span></> : null }
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-3 pt-3 text-right">
                                                        <ul className="list-inline pager-list">
                                                            <li className="list-inline-item"><a href="#" onClick={this.props.history.goBack} className="btn btn-back">Back</a></li>
                                                            <li className="list-inline-item"><a href="#" onClick={(e)=>this.printMappingImage(e)} className="btn btn-back">Print</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* <div className="downloadReport" id="toCanvas">
                                                <BalancedScorecardGoalSetting
                                                    editDoubleClickHandler={()=>{}}
                                                    GoalSettingBlurHandler={()=>{}}
                                                    goalSettingChangeHandler={()=>{}}
                                                    GoalSetting={this.state.goalSetting}
                                                    addGoalSettingRow={()=>{}}
                                                    arrayGoalSettingHandler={()=>{}}
                                                    arrayGoalSettingHandlerEdit={()=>{}}
                                                    arrayGoalSettingBlurHandler={()=>{}}
                                                    goalSettingDeleteRowHandler={()=>{}}
                                                    report = "report"
                                                />
                                                </div> */}
                                                <div className="row bg-white" id="toCanvas" style={{paddingLeft:"15px"}}>                                                
                                                { this.state.showGrantTaskPanel ? 
                                                <>
                                                <div className="col-sm-2 bg-white">
                                                <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{width:"35%"}}>Category</th>
                                                        <th>Legend</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.chartLegend.map((legend,ind)=>{
                                                        return(
                                                            <tr key={ind}>
                                                                <td>{legend.legendName}</td>
                                                                <td style={{paddingTop:"0px", backgroundColor:legend.legendColor}}>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> 
                                                </div>
                                                <div className="col-sm-10">
                                                <div className="bg-white balanced-details-row" style={{paddingTop:"0px"}}>
                                                <div className="col-sm-12 text-center">
                                                <h2 style={{paddingTop:"35px"}}>{this.state.bscName}</h2>
                                                </div>
                                            <Gantt tasks={this.state.gantttasks} 
                                            viewMode="Month" 
                                            viewDate={new Date(new Date().getTime())}
                                            preStepsCount={1}
                                            columnWidth="450" 
                                            listCellWidth=""
                                            onDateChange={(e)=>this.handleTaskChange(e)}
                                            onClick={(e)=>this.handleGrantTaskClick(e)}
                                            TooltipContent="false"
                                            />
                                            </div>
                                                </div> 
                                                </>                                                                                              
                                            
                                            :null }
                                                </div>
                                                
                                            {/* <!-- End Balanced Scorecard Section --> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Body Content --> */}
                            </div>
                        </div>
                    {/* <!-- /End container-fluid --> */}
                    </div>
                {/* <!-- End of Main Content --> */}
                </div>
                {/* <!-- End of Content Wrapper --> */}
                </BalancedScorecardWrapper>
                <AsideComponent />
                <FooterComponent />
            </>
        );
    }
}

const mapStateToProps = state => ({
    errors: state.errors
});

export default connect(mapStateToProps, { startLoader, stopLoader })(BalancedScorecardReport);

