import React from "react";

const OpreationManagmentMain = () => {
    return (
        <div>
            <div className="row mx-0">
                {/* LEFT DIV ******* */}
                <div className="col-lg-3 col-md-2 col-sm-12">
                    <div
                        className=" text-danger my-2 border border-danger"
                        style={{ background: "#FAE2D7" }}
                    >
                        <h3 className="p-1">lkjsdfsdf</h3>
                        <p className="py-3">
                            <h1 className="p-4 text-dark">asdfasd</h1>
                        </p>
                    </div>
                    <div
                        className=" text-danger my-2 border border-danger"
                        style={{ background: "#FAE2D7" }}
                    >
                        <h3 className="p-1">lkjsdfsdf</h3>
                        <p className="py-3">
                            <h1 className="p-4 text-dark">asdfasd</h1>
                        </p>
                    </div>
                    <div
                        className=" text-danger my-2 border border-danger"
                        style={{ background: "#FAE2D7" }}
                    >
                        <h3 className="p-1">lkjsdfsdf</h3>
                        <p className="py-3">
                            <h1 className="p-4 text-dark">asdfasd</h1>
                        </p>
                    </div>
                </div>

                {/* DIV CENTER *** */}
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="border mt-2"  style={{height: '205px'}}>
                        <h5 className="bg-success">Lorem, ipsum dolor.</h5>
                        <p className="pb-3">
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Alias excepturi voluptatibus odio, fugit
                            aspernatur ullam!
                        </p>
                    </div>
                    <div className="border my-2" style={{height: '205px'}}>
                        <h5 className="bg-success">Lorem, ipsum dolor.</h5>
                        <p className="pb-3">
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Alias excepturi voluptatibus odio, fugit
                            aspernatur ullam!
                        </p>
                    </div>
                    <div className="border my-2" style={{height: '205px'}}>
                        <h5 className="bg-success">Lorem, ipsum dolor.</h5>
                        <p className="pb-3">
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Alias excepturi voluptatibus odio, fugit
                            aspernatur ullam!
                        </p>
                    </div>
                </div>

                {/* DIV THIRD ******* */}
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <div>
                        <table class="table text-left table-bordered">
                            <thead
                                className="text-dark text-center"
                                style={{ background: "#F5C4AF" }}
                            >
                                <tr>
                                    <th scope="col" style={{ width: "27%" }}>
                                        Paramters
                                    </th>
                                    <th
                                        // colSpan={2}
                                        className="text-center"
                                        scope="col"
                                        style={{ width: "77%" }}
                                    >
                                        Observations
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        scope="row"
                                        className=" m-2"
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                        }}
                                    >
                                        Lorem ipsum dolor sit
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                            borderLeft: "5px solid #f5c4af",
                                        }}
                                    >
                                        Jacob Lorem ipsum dolor sit amet
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        scope="row"
                                        className=" m-2"
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                        }}
                                    >
                                        Lorem ipsum dolor sit
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                            borderLeft: "5px solid #f5c4af",
                                        }}
                                    >
                                        Jacob Lorem ipsum dolor sit amet
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        scope="row"
                                        className=" m-2"
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                        }}
                                    >
                                        Lorem ipsum dolor sit
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                            borderLeft: "5px solid #f5c4af",
                                        }}
                                    >
                                        Jacob Lorem ipsum dolor sit amet Lorem
                                        ipsum, dolor sit amet consectetur
                                        adipisicing elit. In provident ex odio.
                                        Error at commodi asperiores dolores quo
                                        dolor porro.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        scope="row"
                                        className=" m-2"
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                        }}
                                    >
                                        Lorem ipsum dolor sit
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                            borderLeft: "5px solid #f5c4af",
                                        }}
                                    >
                                        Jacob Lorem ipsum dolor sit amet Lorem
                                        ipsum, dolor sit amet consectetur
                                        adipisicing elit. In provident ex odio.
                                        Error at commodi asperiores dolores quo
                                        dolor porro.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        scope="row"
                                        className=" m-2"
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                        }}
                                    >
                                        Lorem ipsum dolor sit
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                            borderLeft: "5px solid #f5c4af",
                                        }}
                                    >
                                        Jacob Lorem ipsum dolor sit amet Lorem
                                        ipsum, dolor sit amet consectetur
                                        adipisicing elit. In provident ex odio.
                                        Error at commodi asperiores dolores quo
                                        dolor porro.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        scope="row"
                                        className=" m-2"
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                        }}
                                    >
                                        Lorem ipsum dolor sit
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                            borderLeft: "5px solid #f5c4af",
                                        }}
                                    >
                                        Jacob Lorem ipsum dolor sit amet Lorem
                                        ipsum, dolor sit amet consectetur
                                        adipisicing elit. In provident ex odio.
                                        Error at commodi asperiores dolores quo
                                        dolor porro.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        scope="row"
                                        className=" m-2"
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                        }}
                                    >
                                        Lorem ipsum dolor sit
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: "5px solid #f5c4af",
                                            borderLeft: "5px solid #f5c4af",
                                        }}
                                    >
                                        Jacob Lorem ipsum dolor sit amet Lorem
                                        ipsum, dolor sit amet consectetur
                                        adipisicing elit. In provident ex odio.
                                        Error at commodi asperiores dolores quo
                                        dolor porro.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpreationManagmentMain;
