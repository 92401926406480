import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import { KpiSettingWrapper } from './Styling/KpiSetting';

class CardSettingKpi extends Component {
    constructor (props){
        super (props);
        this.state = {
        }
    }
    render(){
		let bgClass = 'bg-warning';
		let index = this.props.ind;
        if (index % 3 === 1) {
            bgClass = 'bg-danger'
        }
        if (index % 3 === 2) {
            bgClass = 'bg-info'
        }

        let bgColor='';
        
        this.props.categoryChartLegend.map(leg=>{
            if(leg.legendID == this.props.KPIID)
            {
                bgColor = leg.legendColor;
            }
        })

        return(
                        <div className="container-fluid container-dashboard pl-0">
                        <KpiSettingWrapper>
						<div className="price-box">
                        <div className="top-bg" style={{backgroundColor:bgColor}}>
                            <p>Business Target Outcome/KPIs</p>
                            <h3>{this.props.heading}</h3>
                        </div>
                        <div className="bottom-content">
                            <div className="mtrics-h">
                                <p className="txt-gray mb-0">Business Metrics</p>
                                <p className="txt-w">{this.props.BusinessMetrics}</p>
                            </div>
                            <div className="mtrics-h">
                                <p className="txt-gray mb-0">BSC Category</p>
                                <p className="txt-w">{this.props.BSCCategory}</p>
                            </div>

                            <div className="mtrics-h">
                                <p className="txt-gray mb-0">Expected Target Growth</p>
                                <p className="txt-w">{this.props.UnitOfMeasurement=='amount'?'$':''}{this.props.ExpectedTargetGrowthAmount}</p>
                            </div>
							<div className="mtrics-h">
                                <p className="txt-gray mb-0">Unit Of Measurement</p>
                                <p className="txt-w">{this.props.UnitOfMeasurement}</p>
                            </div>
                        </div>
                    </div>
						{/* <div class="card" style={{border:"1px solid"}}>
						  <div class="card-header bbg-warning-custom" style={{minHeight:"45px"}}>
						  <h3 class="box-headerlabel card_kpi_data" title={this.props.heading}>{this.props.heading}</h3>
						  </div>
						  <div class="card-body">
						  	<div>
								<h4 class="box-body-label">Business Metrics</h4>
								<h2 class="box-body-value" style={{fontSize:"20px"}}>{this.props.BusinessMetrics}</h2>
							</div>
							<div class="mt-4">
								<h4 class="box-body-label">BSC Category</h4>
								<h2 class="box-body-value" style={{fontSize:"20px"}}>{this.props.BSCCategory}</h2>
							</div>
							<div class="mt-4">
								<h4 class="box-body-label">Expected Target Growth</h4>
								<h2 class="box-body-value" style={{fontSize:"20px"}}>{this.props.UnitOfMeasurement=='amount'?'$':''}{this.props.ExpectedTargetGrowthAmount}</h2>
							</div>
							<div class="mt-4">
								<h4 class="box-body-label">Unit Of Measurement</h4>
								<h2 class="box-body-value" style={{fontSize:"20px"}}>{this.props.UnitOfMeasurement}</h2>
							</div>
							
						  </div>
						 
					</div> */}
                        </KpiSettingWrapper>
                        </div>
        );
    }
}
CardSettingKpi.propTypes = {

}

const mapStateToProps = state => ({
    errors: state.errors
});
export default connect(mapStateToProps, {  })(CardSettingKpi);