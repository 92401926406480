export const FETCH_IOT = 'FETCH_IOT';
export const FETCH_RATINGS = 'FETCH_RATINGS';
export const TOGGLE_LOCK = 'TOGGLE_LOCK';
export const GET_ERRORS = 'GET_ERRORS';
export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const SAVE_PROJECT_DOMAIN='SAVE_PROJECT_DOMAIN';
export const DELETE_PROJECT_DOMAIN='DELETE_PROJECT_DOMAIN';
export const SET_PROJECT_SELECTED_DOMAIN='SET_PROJECT_SELECTED_DOMAIN';
export const FETCH_LINK_PROJECTS = "FETCH_LINK_PROJECTS";
export const FETCH_PROJECT_DEPENDENT_DETAILS="FETCH_PROJECT_DEPENDENT_DETAILS";
export const CAN_SCORE_CHECK_CAUSAL="CAN_SCORE_CHECK_CAUSAL";
export const GET_FPM_MERGE="GET_FPM_MERGE";
export const SAVE_FPM_MERGE="SAVE_FPM_MERGE";
export const GET_CAUSAL_DATA_AND_SCORE="GET_CAUSAL_DATA_AND_SCORE";
export const SAVE_PROJECT_QUESTIONS='SAVE_PROJECT_QUESTIONS';
export const DELETE_PROJECT_QUESTION='DELETE_PROJECT_QUESTION';
export const UPDATE_AFLY_SCORE_QUESTION='UPDATE_AFLY_SCORE_QUESTION';
export const CLEAR_ASSESMENT_EDIT='CLEAR_ASSESMENT_EDIT';
export const HANDLE_SELECT_PROJECT='HANDLE_SELECT_PROJECT';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const DUPLICATE_TEMPLATE ='DUPLICATE_TEMPLATE';
export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const GET_TEMPLATE ='GET_TEMPLATE';
export const GET_SUCCESS = 'GET_SUCCESS';
export const MAKELOADINGTRUE = 'MAKELOADINGTRUE';
export const DELETE_RELATED = 'DELETE_RELATED';
export const ASSOCIATION_DATA = 'ASSOCIATION_DATA';
export const SAVE_CO2EMMISIONTYPE_DATA='SAVE_CO2EMMISIONTYPE_DATA';
export const SAVE_CO2EMMISIONCOUNTRY='SAVE_CO2EMMISIONCOUNTRY';
export const SAVE_CALCULATECO2EMMISION='SAVE_CALCULATECO2EMMISION'
export const GET_AUDIT_HISTORY = 'GET_AUDIT_HISTORY';
export const ADD_CONTROLEVER = 'ADD_CONTROLEVER';
export const NEW_ACTIVITY_LOCATION = 'NEW_ACTIVITY_LOCATION';
export const SAVE_KPI_ASSOCATION = 'SAVE_KPI_ASSOCATION';
export const GET_KPI_AUDIT_TYPES = 'GET_KPI_AUDIT_TYPES';
export const FETCH_KPIS_SET_UESERS = 'FETCH_KPIS_SET_UESERS';
export const GET_FREQUENCY = 'GET_FREQUENCY'; 
export const GET_IMPRVBASIS = 'GET_IMPRVBASIS'; 
export const GET_UOM = 'GET_UOM';
export const GET_UEM = 'GET_UEM';
export const SAVE_KPI_AUDIT = "SAVE_KPI_AUDIT";
export const GET_KPI_AUDIT = "GET_KPI_AUDIT";
export const SAVE_TEMPLATE_DATA = 'SAVE_TEMPLATE_DATA';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const SCORES = 'SCORES';
export const SEARCH_PROCESS = 'SEARCH_PROCESS';
export const FETCH_PROCESS_DATA = 'FETCH_PROCESS_DATA';
export const COPY_PROJECT = 'COPY_PROJECT';
export const CONNECTED_PROCESSES = 'CONNECTED_PROCESSES';
export const APPROVE_ACCOUNT = 'APPROVE_ACCOUNT';
export const SAVE_TEMPLATE_DECOMPOSITION = 'SAVE_TEMPLATE_DECOMPOSITION';
export const FETCH_FUNCTIONS = 'FETCH_FUNCTIONS';
export const FETCH_PROJECT_DOMAINS = 'FETCH_PROJECT_DOMAINS';
export const FETCH_PROJECT_DOMAIN_QUESTION='FETCH_PROJECT_DOMAIN_QUESTION';
export const FETCH_PROJECT_DOMAIN_QUESTION_DETAILS='FETCH_PROJECT_DOMAIN_QUESTION_DETAILS';
export const FETCH_PROJECT_CLIENT_ATTACHMENT='FETCH_PROJECT_CLIENT_ATTACHMENT';
export const FETCH_PHASES = 'FETCH_PHASES';
export const TEMPLATE_PROCESSES = 'TEMPLATE_PROCESSES';
export const FETCH_PROJECT_FUNCTIONS = 'FETCH_PROJECT_FUNCTIONS';
export const FETCH_PROJECT_PHASES = 'FETCH_PROJECT_PHASES';
export const FETCH_MENU = 'FETCH_MENU';
export const FETCH_DASHBORAD_DATA = 'FETCH_DASHBORAD_DATA';
export const FETCH_BENCHMARK_PROJECTS = 'FETCH_BENCHMARK_PROJECTS';
export const FETCH_BENCHMARK_DOMAINS = 'FETCH_BENCHMARK_DOMAINS';
export const FETCH_DOMAIN_QUESTIONS = 'FETCH_DOMAIN_QUESTIONS';
export const FETCH_QUESTION = 'FETCH_QUESTION';
export const SAVE_ANSWER = 'SAVE_ANSWER';
export const FETCH_INDUSTRIES = 'FETCH_INDUSTRIES';
export const FETCH_REGIONS = 'FETCH_REGIONS';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_STATES = 'FETCH_STATES';
export const FETCH_CITIES = 'FETCH_CITIES';
export const FETCH_PROFILE_QUESTIONS = 'FETCH_PROFILE_QUESTIONS';
export const FETCH_COMPANY_PROFILE = 'FETCH_COMPANY_PROFILE';
export const FETCH_SCORING_MECHANISMS = 'FETCH_SCORING_MECHANISMS';
export const NEW_MECHANISM = 'NEW_MECHANISM';
export const PROCESSES = 'PROCESSES';
export const ACTIVITY_BANK = 'ACTIVITY_BANK';
export const FETCH_TEAM_USER = 'FETCH_TEAM_USER';
export const SAVE_TEAM_USER = 'SAVE_TEAM_USER';
export const GET_ACCESS_TYPE = 'GET_ACCESS_TYPE';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_INDIVIDUAL_USER = 'GET_INDIVIDUAL_USER';
export const FETCH_KPIS = 'FETCH_KPIS';
export const FETCH_KPIS_USERS = 'FETCH_KPIS_USERS';
export const FETCH_KPIS_SETS = 'FETCH_KPIS_SETS';
export const FETCH_KPIS_BSC = 'FETCH_KPIS_BSC';
export const FETCH_KPISET_ACCESS = 'FETCH_KPISET_ACCESS';
export const FETCH_KPI_ACCESS_USER = 'FETCH_KPI_ACCESS_USER';
export const FETCH_KPIS_ACCESS = 'FETCH_KPIS_ACCESS';
export const FETCH_KPIS_SET_DETAILS = "FETCH_KPIS_SET_DETAILS";
export const SAVE_KPIS_PERMISSION = 'SAVE_KPIS_PERMISSION';
export const ADD_KPI = 'ADD_KPI';
export const CREATE_KPI = 'CREATE_KPI';
export const GET_KPI = 'GET_KPI';
export const DELETE_KPI = 'DELETE_KPI';
export const DELETE_LEVER = 'DELETE_LEVER';
export const GET_CONTROL_LEVER = 'GET_CONTROL_LEVER';
export const UPDATED_LEVER = 'UPDATED_LEVER';
export const NEW_ACTIVITY = 'NEW_ACTIVITY';
export const SECURITY_QUESTIONS = 'SECURITY_QUESTIONS';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const USER_INFO = 'USER_INFO';
export const RESTRICTED_DOMAINS = 'RESTRICTED_DOMAINS';
export const USER_ACCESS_TYPES = 'USER_ACCESS_TYPES';
export const EMP_REGISTER = 'EMP_REGISTER';
export const USER_PROFILE = 'USER_PROFILE';
export const FETCH_SERVICES = 'FETCH_SERVICES';
export const FETCH_CUSTOM_SCORING_MECHANISMS = 'FETCH_CUSTOM_SCORING_MECHANISMS';
export const SAVE_DECOMPOSITION = 'SAVE_DECOMPOSITION';
export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SAVE_ANSWERS = 'SAVE_ANSWERS';
export const FETCH_PROJECTS_ACCESS = 'FETCH_PROJECTS_ACCESS';
export const FETCH_DOMAINS_ACCESS = 'FETCH_DOMAINS_ACCESS';
export const FETCH_USER_ACCESS = 'FETCH_USER_ACCESS';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_REPORTS = 'FETCH_REPORTS';
export const PROJECT_USERS = 'PROJECT_USERS';
export const FETCH_EPICS = 'FETCH_EPICS';

export const FETCH_FUNCTIONAL_GRID_DATA = 'FETCH_FUNCTIONAL_GRID_DATA';
export const SELECTED_MODULE = 'SELECTED_MODULE';
export const EMPTY_MODULE = 'EMPTY_MODULE';
export const SELECTED_MODULE_PARAMETER = 'SELECTED_MODULE_PARAMETER';

//.. TODO : Refactor > Change when service is clubbed in php  
export const REPORTS_BENCHMARK_MODULE_COUNT = 'REPORTS_BENCHMARK_MODULE_COUNT';
export const REPORTS_CAPABILITY_MODULE_COUNT = 'REPORTS_CAPABILITY_MODULE_COUNT';
export const REPORTS_KPI_MODULE_COUNT = 'REPORTS_KPI_MODULE_COUNT';

export const CAPABILITY_USER_PERMISSIONS = 'CAPABILITY_USER_PERMISSIONS';
export const ENTERPRISE_ACCOUNTS = 'ENTERPRISE_ACCOUNTS';
export const TEMPLATES = 'TEMPLATES';
export const CLIENT_DETAILS = 'CLIENT_DETAILS';
export const UPDATE_ENTERPRISE_ACCOUNT = 'UPDATE_ENTERPRISE_ACCOUNT';
export const MARKET_INDUSTRIES = 'MARKET_INDUSTRIES';
export const SAVE_FUNCTION = 'SAVE_FUNCTION';
export const SAVE_PHASE = 'SAVE_PHASE';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const TEMPLATE_STYLES = 'TEMPLATE_STYLES';

export const BALANCED_SCORECARD_KPI = 'BALANCED_SCORECARD_KPI';
export const BALANCED_SCORECARD_CATEGORIES = 'BALANCED_SCORECARD_CATEGORIES';


export const FETCH_STAKEHOLDERS = 'FETCH_STAKEHOLDERS';
export const FETCH_NOI = 'FETCH_NOI';
export const FETCH_DTPROJECTS = 'FETCH_DTPROJECTS';
export const DTPROJECT_CREATE_PERMISSION = 'DTPROJECT_CREATE_PERMISSION';
export const FETCH_STAKEHOLDERS_TYPE = 'FETCH_STAKEHOLDERS_TYPE';
export const FETCH_BENCHMARK_PROJECTS_WITH_DOMAINS = 'FETCH_BENCHMARK_PROJECTS_WITH_DOMAINS';
export const FETCH_DECOMPOSITION_PROJECTS = 'FETCH_DECOMPOSITION_PROJECTS';
export const FETCH_KPIS_CONTROLS = 'FETCH_KPIS_CONTROLS';
export const FETCH_DESIGNTHINKING_PROJECTS = 'FETCH_DESIGNTHINKING_PROJECTS';
export const GET_AUDIT_NAMES = 'GET_AUDIT_NAMES';
export const CAPABILITY_VERSION = 'CAPABILITY_VERSION';
export const SAVE_QUESTIONS_BANK = 'SAVE_QUESTIONS_BANK'; 
export const FETCH_DT_STAKEHOLDER_LOCATIONS = 'FETCH_DT_STAKEHOLDER_LOCATIONS';
export const FETCH_DT_STAKEHOLDER_MANAGERS = 'FETCH_DT_STAKEHOLDER_MANAGERS';
export const FETCH_DT_STAKEHOLDER_DEPARTMENTS = 'FETCH_DT_STAKEHOLDER_DEPARTMENTS';
export const SAVE_CLIENT_QUESTIONS_BANK = 'SAVE_CLIENT_QUESTIONS_BANK';
export const SAVE_AMPLO_QUESTIONS_ANSWERS = 'SAVE_AMPLO_QUESTIONS_ANSWERS';
export const SAVE_AMPLO_DOMAINS = 'SAVE_AMPLO_DOMAINS';  
export const SAVE_CLIENT_DOMAINS = 'SAVE_CLIENT_DOMAINS';  
export const GET_AMPLO_QUESTIONS_BANK = 'GET_AMPLO_QUESTIONS_BANK';
export const GET_CLIENT_QUESTIONS_BANK = 'GET_CLIENT_QUESTIONS_BANK'; 
export const GET_AMPLO_DOMAINS = 'GET_AMPLO_DOMAINS';
export const GET_CLIENT_DOMAINS = 'GET_CLIENT_DOMAINS'; 
export const GET_AMPLO_QUESTIONS_BY_DOMAIN = 'GET_AMPLO_QUESTIONS_BY_DOMAIN';
export const GET_CLIENT_QUESTIONS_BY_DOMAIN = 'GET_CLIENT_QUESTIONS_BY_DOMAIN';
export const FETCH_ALL_BALANCED_SCORECARDS = 'FETCH_ALL_BALANCED_SCORECARDS';
export const FETCH_ALL_KPI_AGAINST_BALANCED_SCORECARDS = 'FETCH_ALL_KPI_AGAINST_BALANCED_SCORECARDS'; 
export const FETCH_KPI_SETS_FOR_USER = 'FETCH_KPI_SETS_FOR_USER'; 
export const FETCH_KPI_AUDIT_DATA = 'FETCH_KPI_AUDIT_DATA'; 
export const FETCH_AM_PERCENTAGE_VAL = 'FETCH_AM_PERCENTAGE_VAL';
export const FETCH_CM_PERCENTAGE_VAL = 'FETCH_CM_PERCENTAGE_VAL';
export const GET_UNANSWERED_QUESTIONS = 'GET_UNANSWERED_QUESTIONS';
export const FETCH_DASHBORAD_WIDGETS = "FETCH_DASHBORAD_WIDGETS"
export const FETCH_DT_STAKEHOLDER_BUSINESS_ENTITY = 'FETCH_DT_STAKEHOLDER_BUSINESS_ENTITY';
export const FETCH_DT_STAKEHOLDER_EMPLOYEE_TYPE = 'FETCH_DT_STAKEHOLDER_EMPLOYEE_TYPE';
export const FETCH_DT_STAKEHOLDER_MODEL = 'FETCH_DT_STAKEHOLDER_MODEL';
export const FETCH_DT_STAKEHOLDER_PARTNER_TYPE = 'FETCH_DT_STAKEHOLDER_PARTNER_TYPE';
export const FETCH_DT_STAKEHOLDER_ORGANIZATIONAL_LEVEL = 'FETCH_DT_STAKEHOLDER_ORGANIZATIONAL_LEVEL';
export const FETCH_DT_STAKEHOLDER_ASSET_CATEGORY = 'FETCH_DT_STAKEHOLDER_ASSET_CATEGORY';
export const FETCH_DT_STAKEHOLDER_ASSET_TYPE = 'FETCH_DT_STAKEHOLDER_ASSET_TYPE';
export const SAVE_DT_STAKEHOLDER = 'SAVE_DT_STAKEHOLDER';
export const ERROR = 'ERROR'; 
export const FETCH_DASHBOARD_WIDGETS = "FETCH_DASHBOARD_WIDGETS"
export const GET_SUPPORT_MENU = "GET_SUPPORT_MENU";
export const GET_SUPPORT_DETAILS = "GET_SUPPORT_DETAILS";
export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN";
export const GET_EMBED_TOKEN = "GET_EMBED_TOKEN";
export const GET_BI_REPORT = "GET_BI_REPORT";
export const GET_POWERBI_REPORTS = "GET_POWERBI_REPORTS";
export const EMPTY_POWERBI_REPORTS = "EMPTY_POWERBI_REPORTS";
export const CURRENT_SELECTED_DOMAIN = 'CURRENT_SELECTED_DOMAIN';
export const CURRENT_SELECTED_QUESTIONS_BANK = 'CURRENT_SELECTED_QUESTIONS_BANK';
export const SAVE_CLIENT_QUESTIONS_ANSWERS = 'SAVE_CLIENT_QUESTIONS_ANSWERS';
export const CURRENT_SELECTED_DOMAIN_AMPLO = 'CURRENT_SELECTED_DOMAIN_AMPLO';
export const CURRENT_SELECTED_QUESTIONS_BANK_AMPLO = 'CURRENT_SELECTED_QUESTIONS_BANK_AMPLO';
export const GET_ALL_QUESTION_STYLES_LISTING = 'GET_ALL_QUESTION_STYLES_LISTING';
export const SAVE_QUESTIONS_ATTACHMENT = 'SAVE_QUESTIONS_ATTACHMENT';
export const GET_QUESTIONS_ATTACHMENT = 'GET_QUESTIONS_ATTACHMENT';
export const GET_CLIENT_SIDEBAR_DOMAINS_QUESTIONS = 'GET_CLIENT_SIDEBAR_DOMAINS_QUESTIONS';
export const DELETE_CLIENT_ATTACHMENTS = 'DELETE_CLIENT_ATTACHMENTS';
export const DELETE_CLIENT_QUESTIONS = 'DELETE_CLIENT_QUESTIONS';
export const DELETE_AMPLO_QUESTIONS = 'DELETE_AMPLO_QUESTIONS';
export const DELETE_CLIENT_DOMAINS = 'DELETE_CLIENT_DOMAINS';
export const DELETE_AMPLO_DOMAINS = 'DELETE_AMPLO_DOMAINS';
export const DELETE_CLIENT_QUESTIONS_BANK = 'DELETE_CLIENT_QUESTIONS_BANK';
export const DELETE_AMPLO_QUESTIONS_BANK = 'DELETE_AMPLO_QUESTIONS_BANK';
export const SAVE_AMPLO_QUESTIONS_ATTACHMENT = 'SAVE_AMPLO_QUESTIONS_ATTACHMENT';
export const GET_AMPLO_QUESTIONS_ATTACHMENT = 'GET_AMPLO_QUESTIONS_ATTACHMENT';
export const DELETE_AMPLO_ATTACHMENTS = 'DELETE_AMPLO_ATTACHMENTS';
export const GET_AMPLO_SIDEBAR_DOMAINS_QUESTIONS = 'GET_AMPLO_SIDEBAR_DOMAINS_QUESTIONS';
export const GET_CLIENT_ASSIGNMENT = 'GET_CLIENT_ASSIGNMENT';
export const SAVE_CLIENT_ASSIGNMENT = 'SAVE_CLIENT_ASSIGNMENT';
export const ASSIGN_QUESTIONS_BANK_TO_AMPMARKING_PROJECT = 'ASSIGN_QUESTIONS_BANK_TO_AMPMARKING_PROJECT';
export const GET_AFLY_SCORE = 'GET_AFLY_SCORE';
export const GET_AFLY_DOMAIN_SCORE = 'GET_AFLY_DOMAIN_SCORE';
export const SAVE_CLIENT_DUPLICATE_QUESTIONS_BANK = 'SAVE_CLIENT_DUPLICATE_QUESTIONS_BANK';
export const SAVE_AMPLO_DUPLICATE_QUESTIONS_BANK = 'SAVE_AMPLO_DUPLICATE_QUESTIONS_BANK';
export const SAVE_CLIENT_ASSIGNED_INDUSTRY_CLASSIFICATION = 'SAVE_CLIENT_ASSIGNED_INDUSTRY_CLASSIFICATION';
export const SAVE_AMPLO_ASSIGNED_INDUSTRY_CLASSIFICATION = 'SAVE_AMPLO_ASSIGNED_INDUSTRY_CLASSIFICATION';

export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const FETCH_OTHER_PROJECTS_OF_USER = 'FETCH_OTHER_PROJECTS_OF_USER';
export const FETCH_NOI_TEMPLATES = 'FETCH_NOI_TEMPLATES';
export const FETCH_DT_STAKEHOLDERS = 'FETCH_DT_STAKEHOLDERS';
export const FETCH_DT_PROJ_DETAILS = 'FETCH_DT_PROJ_DETAILS';
export const FETCH_EPIC = 'FETCH_EPIC';
export const FETCH_EPIC_VERSION_HISTORY = 'FETCH_EPIC_VERSION_HISTORY';
export const SAVE_EPIC = 'SAVE_EPIC';
export const FETCH_DECOMP_PROJ_FN_PHASE_LVL = 'FETCH_DECOMP_PROJ_FN_PHASE_LVL';
export const EPIC_LOCK_UNLOCK = 'EPIC_LOCK_UNLOCK';
export const FETCH_ALL_EPIC = 'FETCH_ALL_EPIC';
export const FETCH_EPIC_PROJECT = 'FETCH_EPIC_PROJECT';
export const FETCH_EPIC_SUBEPIC_DETAILS = 'FETCH_EPIC_SUBEPIC_DETAILS';
export const EMPATHY_NOTES_STAKEHOLDERS = 'EMPATHY_NOTES_STAKEHOLDERS';
export const EMPATHY_NOTES = 'EMPATHY_NOTES';
export const GET_ALL_EMPATHY_LIFECYCLE = 'GET_ALL_EMPATHY_LIFECYCLE';
export const SAVE_EMPATHY_MAP = 'SAVE_EMPATHY_MAP';
export const GET_EMPATHY_NOTES_STK_DESC = 'GET_EMPATHY_NOTES_STK_DESC';
export const DELETE_ALL_EMPATHY_NOTES = 'DELETE_ALL_EMPATHY_NOTES';
export const FETCH_IDEAS = 'FETCH_IDEAS';
export const FETCH_SWOT = 'FETCH_SWOT';
export const SAVE_PERSONA_DETAILS = 'SAVE_PERSONA_DETAILS';
export const GET_ALL_PERSONA = 'GET_ALL_PERSONA';
export const GET_PERSONA_DETAILS = 'GET_PERSONA_DETAILS';
export const GET_ALL_LIFECYCLE_FOR_PERSONA = 'GET_ALL_LIFECYCLE_FOR_PERSONA';
export const GET_ALL_STAKLEHOLDERS_FOR_PERSONA = 'GET_ALL_LIFECYCLE_FOR_PERSONA';
export const GET_ALL_MOTIVATION_CATEGORY_FOR_PERSONA = 'GET_ALL_MOTIVATION_CATEGORY_FOR_PERSONA';
export const GET_ALL_PERSONALITY_CATEGORY_FOR_PERSONA = 'GET_ALL_PERSONALITY_CATEGORY_FOR_PERSONA';
export const DELETE_PERSONA = 'DELETE_PERSONA';
export const ENABLE_SWOT_VOTING = 'ENABLE_SWOT_VOTING';
export const FETCH_LOOKUPS = 'FETCH_LOOKUPS';
export const FETCH_PROBLEM_PINNING = 'FETCH_PROBLEM_PINNING';
export const FETCH_CJM = 'FETCH_CJM';
export const CREATE_CJM = 'CREATE_CJM';
export const GET_DTNOI_STK = 'GET_DTNOI_STK';
export const FETCH_OWNERS = 'FETCH_OWNERS';
export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_NOE = 'FETCH_NOE';
export const FETCH_PROTOTYPE = 'FETCH_PROTOTYPE';
export const SAVE_PROTOTYPE = 'SAVE_PROTOTYPE';
export const SAVE_PROTOTYPE_SESSION = 'SAVE_PROTOTYPE_SESSION';
export const UPLOAD_FILE_PROTOTYPE = 'UPLOAD_FILE_PROTOTYPE';
export const DELETE_SHAPES_TEXTS_POSTIDS = 'DELETE_SHAPES_TEXTS_POSTIDS';
export const DELETE_PROTOTYPE_FILES = 'DELETE_PROTOTYPE_FILES';
export const FETCH_NOE_BY_SUBEPIC = 'FETCH_NOE_BY_SUBEPIC';
export const FETCH_DT_CHAMPION = 'FETCH_DT_CHAMPION';
export const EXPORT_QUESTIONS_BANK = 'EXPORT_QUESTIONS_BANK';
export const EXPORT_AMPLO_QUESTIONS_BANK = 'EXPORT_AMPLO_QUESTIONS_BANK';
export const IMPORT_GET_DOMAINS_BY_QUESTIONBANK = 'IMPORT_GET_DOMAINS_BY_QUESTIONBANK';
export const IMPORT_GET_QUESTIONSBANK_BY_BATCHID = 'IMPORT_GET_QUESTIONSBANK_BY_BATCHID';
export const IMPORT_GET_QUESTIONS_BY_DOMAIN = 'IMPORT_GET_QUESTIONS_BY_DOMAIN';
export const IMPORT_GET_INTERFACE_ATTACHMENT_BY_BATCHID = 'IMPORT_GET_INTERFACE_ATTACHMENT_BY_BATCHID';
export const IMPORT_GET_AMPLO_DOMAINS_BY_QUESTIONBANK = 'IMPORT_GET_AMPLO_DOMAINS_BY_QUESTIONBANK';
export const IMPORT_GET_AMPLO_QUESTIONSBANK_BY_BATCHID = 'IMPORT_GET_AMPLO_QUESTIONSBANK_BY_BATCHID';
export const IMPORT_GET_AMPLO_QUESTIONS_BY_DOMAIN = 'IMPORT_GET_AMPLO_QUESTIONS_BY_DOMAIN';
export const IMPORT_GET_AMPLO_INTERFACE_ATTACHMENT_BY_BATCHID = 'IMPORT_GET_AMPLO_INTERFACE_ATTACHMENT_BY_BATCHID';
export const EDIT_CLIENT_FUNC_PHASE_TEMP = 'EDIT_CLIENT_FUNC_PHASE_TEMP';
export const EDIT_ADMIN_FUNC_PHASE_TEMP = 'EDIT_ADMIN_FUNC_PHASE_TEMP';
export const ACTIVITY_LOCATION = 'ACTIVITY_LOCATION';
export const FETCH_TEMPLATE_CLIENTS = 'FETCH_TEMPLATE_CLIENTS';
export const GET_SUPPORT_PAGE_DETAILS = 'GET_SUPPORT_PAGE_DETAILS';
export const GET_AMPLO_CLIENT_QUESTIONS_BANK = 'GET_AMPLO_CLIENT_QUESTIONS_BANK';


export const GET_REPORTS_DATA = "GET_REPORTS_DATA";