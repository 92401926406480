import styled from 'styled-components';

export const PainPointsWrapper = styled.div`
modal .modal-content{
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #cccccc;
}
.modal .modal-title{
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  color: #000000;
  margin-top: 30px;
}
.modal h2{
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  text-align: center;
  color: #000000;
}
.modal .fa-check-circle{
  font-size: 70px;
  color: #a7df6d;
}
.custom-control .custom-control-label{
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #000000;
}
.custom-control-label::after, .custom-control-label::before{
  top: 0;
}
.modal .form-group{
  width: 100%;
}
.modal .form-group label{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2f353a;
}

.modal .custom-select{
  width: 295px;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.3px;
  color: #73818f;
}
.modal p{
 font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #000000;
}
.modal .btn-primary{
  width: 164px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #083ac8;
  background-color: #083ac8;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ffffff;
  font-family: Montserrat;
}
.modal .btncancel{
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: 0.12px;
  color: #083ac8;
}
.modal .close img{
  width: 15px;
}

.heatmap-header {
    padding: 25px 25px 0px 1px;
  }
  .heatmap-header h2{
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.01px;
    color: #000000;
    margin: 0;
  
  }
  .heatmap-header .backlink{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #083ac8;
  }
  .heatmap-table .table-striped{
  border:1px solid #dee2e6;
  }
  .viewAllInActive {
    margin-left: 50%;
    background-color: #ccc;
    padding: 10px 20px 5px 20px;
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-decoration:none;
  }
  .viewAllActive {
    margin-left: 50%;
    background-color: rgb(32, 168, 216);
    padding: 10px 20px 5px 20px;
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-decoration:none;
  }
  .heatmap-table th{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    color: #ffffff;
  }
  .heatmap-table td{
    padding: 20px 15px;
  }
  .heatmap-table th{
    vertical-align: middle !important;
    padding: 14px 15px;
  }
  .heatmap-table .hlist{
    padding: 0 0 0 15px;
   margin: 0;
  }
  .heatmap-table li::marker{
    content:none;
  }
  .heatmap-table .hlist li{
    padding: 0;
    margin: 0 0 10px 0;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: 0.24px;
    color: #2f353a;
   }
   /* .heatmap-table .hlist li:last-child{
    margin: 0;
   } */
  .heatmap-table td{
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: 0.24px;
    color: #2f353a;
   }
   .heatmap-content .card .card-body{
     padding: 0
   }
   .table-fixx-block{
    display:block;
    width:100%;
   }
   .table-fixx-block thead{
    display:block;
    width:100%;
   }
   .table-fixx-block tbody{
    display:block;
    max-height:340px;
    overflow-y:auto;
    overflow-x:hidden;
   }
   .btn-outline-primary-tab {
    color: #97979A;
    background-color:#EBF6FB;
    border-color: #EBF6FB;
}
.bgactive{
  background-color:#4CA4D5;
}
.table .thead-dark th{color:#212529;background-color:#ffffff;border-color:#ffffff; text-align:left !important}

.table thead th {
  position: inherit;
}

.btn-primary {
  color: #fff;
  background-color: #1232C3;
  border-color: #1232C3;
}
#wrapper{
  margin-top:30px !important;
}

.btn-disabled{
  color: #9d9b9b;
  background-color: #c6c9cd;
  border-color: #6193db;
}

.dropdown-menu.show {
  display: block;
  top: 10px !important;
}

.swal-modal{
  width: 578px !important;
}
.swal-button{
  background-color: #007320;
}
.processtdcolor{
  color: #b9b6b6 !important;
}
.tdtextright{
  text-align: right !important;
}
.btn-disabled {
  color: #9d9b9b;
  background-color: #c6c9cd;
  border-color: #d1d1d1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #d5d5d5 !important;
  opacity: 1;
}

`