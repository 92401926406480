import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select";

const balancedScorecardDependencyTabular = props => {
    let showactual = false;
    //console.log('--hb-- categoryNameKpiNameControlLevers');
    //console.log(props.categoryNameKpiNameControlLevers);
    /*console.log('--hb-- categoryRows');
    console.log(props.categoryRows); */

    

    let ddc= 0;
    

    let categoryKpiRows = props.categoryNameKpiNameControlLevers.map((row, rowInd)=>{
        

        let kpis = row.Kpi.map((kpi,ind)=>{
            
            /* ------ */
            let controlLevers = kpi.ControlLevers.map((controlLever,controlLeverInd)=>{
                ddc++;
                let ddTaskDependencyArray = [];    
                let disp = 'Y';
                if(controlLever.DisplayDependency.length >1)
                {                    
                    let tempddArray = controlLever.DisplayDependency.split(',');     
                    tempddArray.forEach(function(obj){
                        //console.log(obj);          
                        let dependencyIdtoCheckCircular = controlLever.DependencyId+'-'+obj;              
                            props.ddoptions.forEach(function(ddoptionsobj){
                                
                                if(obj == ddoptionsobj.value)
                                {
                                    ddTaskDependencyArray.push({'value':ddoptionsobj.value, 'label':ddoptionsobj.label})
                                }
                                
                            }); 

                        
                        
                        
                    });               
                    
                }

                let displayddoptions = [];
                props.ddoptions.forEach(function(ddoptionsobj){
                    //console.log(ddoptionsobj);
                    if(controlLever.DependencyId != ddoptionsobj.value && props.circular == 'N')
                    {
                        displayddoptions.push({'value':ddoptionsobj.value, 'label':ddoptionsobj.label})
                    }
                    
                }); 
                //console.log('ddTaskDependencyArray',ddTaskDependencyArray);
                //alert(props.circular);
                return(
                <>
                {(controlLever.ControlLeversTitle == null && controlLever.KPIControlLeversID == null) ? null : <>
                <tr className="nospacing thead-controllever">
                <td className="nospacing somepadding"><span className="pclass4">{controlLever.DependencyId}</span></td>
                <td className="nospacing somepadding"></td>
                <td className="nospacing somepadding"></td>
                <td className="nospacing somepadding"><span className="pclass4">{controlLever.ControlLeversTitle}</span> </td>
                <td className="somepadding pl-1" style={{paddingRight:"10px !important"}}>
                <Select className="selectddmenu" 
                key={ddc} 
                color='#6a6969'
                closeOnSelect={true}
                options={displayddoptions} 
                multi={true} 
                backspaceDelete={true}
                keepSelectedInList={false}
                values={ddTaskDependencyArray}
                onChange={(evt) => props.changeSubKpiDependencyDropdown(controlLever.KPIControlLeversID,evt,controlLever.DependencyId)} 
                onDropdownOpen={(evt) => props.markModificationStarted()}
                placeholder="Select Sub KPI" />    
                    </td>
                </tr>
                </> }
                
                </>
                );
            })
            /* ------ */

            let bgColor='';
        
            props.categoryChartLegend.map(leg=>{
                if(leg.legendID == kpi.KpiId)
                {
                    bgColor = leg.legendColor;
                }
            })

            return(
            <>
            <tr className="nospacing thead-kpiname" style={{backgroundColor:bgColor}}>
            <td className="nospacing somepadding"><span className="pclass3">{kpi.DependencyId}</span></td>
            <td className="nospacing somepadding"></td>
            <td className="nospacing somepadding"><span className="pclass3"><b>{kpi.KpiName}</b></span> </td>
            <td className="nospacing somepadding"></td>
            <td className="nospacing somepadding"></td>
            </tr>
            {controlLevers}
            </>
            );
        })
    return(
        <>
        <tr className="nospacing thead-categoryname" style={{backgroundColor:'#ffffbe'}}>
        <td className="nospacing somepadding"><span className="pclass2">{row.DependencyId}</span></td>
        <td className="nospacing somepadding"><span className="pclass2">{row.title}</span></td>
        <td className="nospacing somepadding"></td>
        <td className="nospacing somepadding"></td>
        <td className="nospacing somepadding"></td>
        </tr>
        {kpis}
        </>        
        )
    })

    return(
        <>
            <div className="bg-white balanced-details-row nopadding">
                <div className="balanced-col-wraper">
                    <div className="row" >
                        <div className="table-responsive">
                            <table className="ctable objectives-tbl">
                                <thead className="thead-dark">
                                <tr className="nospacing">
                                    <th className="nospacing pl-10" style={{width:"10%", textAlign:"left"}}>
                                        <span className="pclass1">{" "}SERIAL NO.</span>
                                    </th>
                                    <th className="nospacing pl-10" style={{width:"14%", textAlign:"left"}}>
                                        <span className="pclass1">{" "}CATEGORY</span>
                                    </th>
                                    <th className="nospacing pl-10" style={{width:"25%", textAlign:"left"}}>
                                        <span className="pclass1">{" "}KPI NAME</span>
                                    </th>
                                    <th className="nospacing" style={{width:"25%",textAlign:"left"}}>
                                        <span className="pclass1">SUB KPI/CONTROL LEVER</span>
                                    </th>
                                    <th className="nospacing" style={{width:"25%",textAlign:"left"}}>
                                        <span className="pclass1">DEPENDENCY PREDECESSOR</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {categoryKpiRows}                                                                                                 
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="5" style={{height:"50px",textAlign:"left"}}></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>


            </div>
        </>
    )
}

export default balancedScorecardDependencyTabular;