import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Label } from 'reactstrap';
import { Link} from 'react-router-dom';
import _ from 'underscore';

import DashboardHeader from "../includes/dashboardHeader/DashboardHeader";
import DashboardSidebar from "../includes/dashboardSidebar/DashboardSidebar";
import {PainPointsWrapper} from './Styling/PainPointInitiatives'
import FooterComponent from '../includes/dashboardFooter/FooterComponent';
import ModalPopup from '../common/ModalPopup';
import {errorAlert,responseMessage} from '../../utils/alert';
import { confirmAlert } from 'react-confirm-alert';
import swal from "sweetalert";
import { startLoader, stopLoader } from '../../actions/loaderActions';

import SidebarJs from '../../common/js/sidebarAnimation';
import  Loader  from '../Loader';
import {saveClientAnalyticsUsecaseruns,getClientAnalyticsUsecaserunsDetails,fetchPainPointsInitiatives,GetCapabilityPainPoints,setProject,resetInitiatives} from '../../actions/benchmarkingActions';
import { fetchProjects ,fetchLinkProjects } from '../../actions/capabilityActions';
import CacheStorage from '../../utils/CacheStorage';
import axios from 'axios';
// 
// import $ from 'jquery'
// import ReactTable from 'react-table';

const config = require('../../config');
//.. Reviewed by Ashim:: Need to implement LoadAPI > action creators

class PainPointInitiatives extends Component {
    constructor(props){
        console.log(props);
        super(props)
        this.sidebarAnimation = new SidebarJs();
        this.state = {
        projectId:0,
        painpointsData:[],
        capabilityPainPointsData:[],
        overviewRiskprofileData:[],
        activityRiskprofileData:[],
        riskprofileCapabilityData:[],
        unSelectedCapabilityData:[],
        checkedUnselectedCapabilityData:[],
        viewAll:true,
        tabActiveState:'overview',
        listOrDomain: 'list',
        openRiskProfileAddCapabilityPanel:false,
        unselectedCapabilityModificationStarted:false,
        checkedRiskprofileCapabilityData: [],
        riskprofileCapabilityModificationStarted:false,
        unselectedAFlyScoreSortType:'High',
        unselectedPrioritySortType:'High',
        riskprofileAFlyScoreSortType:'High',
        riskprofilePrioritySortType:'High',
        finalsaveMappingMatrix:[],
        isOpen: false,
        overviewModalTitle:'',
        overviewModalDesc:'',
        isOpenEditModal:false,
        editRootCauseData:'',
        editSolutionData:'',
        editKPIData:'',
        editdecompositionprocesslevelid_1:''  
        }
        this.viewAllClick = this.viewAllClick.bind(this);
        this.backClick = this.backClick.bind(this);
    }
  
    
    groupBy(arr, key) {      

      if(this.state.listOrDomain == 'domain')
      {
        arr.sort(function(a, b){
          let x = a.Domain;
          let y = b.Domain;
    
          if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
          
        }); 

      }
       

      var newArr = [],
          types = {},
          newItem, i, j, cur;
      for (i = 0, j = arr.length; i < j; i++) {
          cur = arr[i];
          if (!(cur[key] in types)) {
              types[cur[key]] = { type: cur[key], data: [] };
              newArr.push(types[cur[key]]);
          }
          types[cur[key]].data.push(cur);
      }
      return newArr;
}

checkdomainviewcapabilityexists(capabilityType,domainName)
{
  if(capabilityType == 'riskprofile')
  {
    let riskprofileCapabilityData = [];
    let unSelectedCapabilityDomainviewData = this.groupBy(this.state.riskprofileCapabilityData, 'Domain');
    unSelectedCapabilityDomainviewData.map((row, rowInd)=>{
      if(row.type == domainName)
      {
        riskprofileCapabilityData = row.data;
      }
    })

    let returnType = false;

    riskprofileCapabilityData.map((row, rowInd)=>{
      if(this.state.checkedRiskprofileCapabilityData.includes(row.UniqueId))
      {          
        returnType = true;             
      }
    })

    return returnType;

  }else if(capabilityType == 'unselected')
  {
    let riskprofileCapabilityData = [];
    let unSelectedCapabilityDomainviewData = this.groupBy(this.state.unSelectedCapabilityData, 'Domain');
    unSelectedCapabilityDomainviewData.map((row, rowInd)=>{
      if(row.type == domainName)
      {
        riskprofileCapabilityData = row.data;
      }
    })

    let returnType = false;

    riskprofileCapabilityData.map((row, rowInd)=>{
      if(this.state.checkedUnselectedCapabilityData.includes(row.UniqueId))
      {          
        returnType = true;             
      }
    })

    return returnType;
    
  }else{
    return false;
  }
}

toggle(e,modalTitle,modalDesc) {
  e.preventDefault();
  if(modalTitle == null && modalDesc == null)
  {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      overviewModalTitle:'',
      overviewModalDesc:''
  }))

  }else{
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      overviewModalTitle:modalTitle,
      overviewModalDesc:modalDesc
    }))

  }
  
 
}

async  inputChangeHandler(e){
  let value = e.target.value;
  let decimals = 0;
  if(e.target.name == "edit_rootcause"){
    await this.setState({'editRootCauseData':value});
  }
  else if(e.target.name == "edit_solution"){
    await this.setState({'editSolutionData':value});
  }
  else if(e.target.name == "edit_kpi"){
    await this.setState({'editKPIData':value});
  }
  
}

saveRiskProfilPainPoint = () => {
  let finalArray=[]
  let projectId = this.state.projectId;

      let obj={
        "projectId": projectId,
          "decompositionprocesslevelid_1": this.state.editdecompositionprocesslevelid_1,
          "rootcause":this.state.editRootCauseData,
          'solution':this.state.editSolutionData,
          'kpi':this.state.editKPIData

      }
      axios.post(`${config.laravelBaseUrl}saveRiskProfilPainPoint/${projectId}`,obj, {
          headers: {
              authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
      })
      .then((res) => {
          responseMessage("success", 'Saved Successfully', "");
          this.setState({isOpenEditModal:false,editRootCauseData:'',editSolutionData:'',editKPIData:'',editdecompositionprocesslevelid_1:''});
          this.getOverViewRiskProfile(projectId);
      })
      .catch((error) => console.log(error));
}

getActivityRiskProfile = (projectId) =>{
  axios.get(`${config.laravelBaseUrl}getActivityRiskProfile/${projectId}`,
  {
    headers: {
        "authorization": "Bearer " + sessionStorage.getItem("userToken")
    }
 }).then(res => {
      //console.log(res.data.data)
      if(res.data.data.length > 0)
      {
        this.setState({activityRiskprofileData:res.data.data});
        
      }
      

 }).catch(error =>  responseMessage("Error", "Something went wrong while fetching activity data!", ""))
}

getOverViewRiskProfile = (projectId) =>{
  axios.get(`${config.laravelBaseUrl}getOverViewRiskProfile/${projectId}`,
  {
    headers: {
        "authorization": "Bearer " + sessionStorage.getItem("userToken")
    }
 }).then(res => {
      //console.log(res.data.data)
      if(res.data.data.length > 0)
      {
        this.setState({overviewRiskprofileData:res.data.data});

        
      }
      
      this.getActivityRiskProfile(projectId);

 }).catch(error =>  responseMessage("Error", "Something went wrong while fetching overview data!", ""))
}

    getRiskProfileUnselCapability = (projectId) =>{
      axios.get(`${config.laravelBaseUrl}getRiskProfileUnselCapability/${projectId}`,
      {
        headers: {
            "authorization": "Bearer " + sessionStorage.getItem("userToken")
        }
     }).then(res => {
          //console.log(res.data.data)
          if(res.data.data.length > 0)
          {
            this.setState({unSelectedCapabilityData:res.data.data, projectId:projectId});

            
          }
          
          
     }).catch(error =>  responseMessage("Error", "Something went wrong while fetching unselected capabilities!", ""))
  }



getRiskProfileselCapability = (projectId) =>{
    axios.get(`${config.laravelBaseUrl}getRiskProfileselCapability/${projectId}`,
    {
      headers: {
          "authorization": "Bearer " + sessionStorage.getItem("userToken")
      }
   }).then(res => {
        //console.log(res.data.data)
        if(res.data.data.length > 0)
        {
          this.setState({riskprofileCapabilityData:res.data.data })
        }
        

   }).catch(error =>  responseMessage("Error", "Something went wrong while fetching Risk Profile Capabilities!", ""))
}

    componentDidMount(){
      this.sidebarAnimation.toggle();
      let projectId = this.props.match.params.projectId;
      let projName = this.props.match.params.projectName;
      let ParentProcessId=this.props.match.params.processID;
      //this.props.fetchPainPointsInitiatives(projectId);
      this.props.GetCapabilityPainPoints(projectId,ParentProcessId,this.state.viewAll);
      // this.props.setProject({projId:projectId,projName:projName})    
      this.props.fetchProjects();
      this.getOverViewRiskProfile(projectId);
      this.getRiskProfileUnselCapability(projectId);
      this.getRiskProfileselCapability(projectId);

    }

    componentWillUnmount(){
      this.props.resetInitiatives();
      CacheStorage.removeItem("painpoint-back-link");
    }
    viewAllClick(){
       this.setState({capabilityPainPointsData: [] });
       this.props.resetInitiatives();
       let projectId = this.props.match.params.projectId; 
       let projName = this.props.match.params.projectName;
       let ParentProcessId=this.props.match.params.processID; 
       this.setState({viewAll: !this.state.viewAll });
       this.props.GetCapabilityPainPoints(this.props.match.params.projectId,ParentProcessId,!this.state.viewAll);
    }

    redirectToProject=(projectName,projectId,Level)=>{
      
           this.setState({capabilityPainPointsData: [] });
           this.props.resetInitiatives();
           CacheStorage.setItem("painpoint-back-link",'/painpoint-initiatives/'+this.props.match.params.projectId+'/'+this.props.match.params.projectName+'/'+this.props.match.params.processID);
           this.props.history.push( 
             '/painpoint-initiatives/'+this.props.match.params.projectId+'/'+'L'+Level+'.'+projectName+'/'+projectId
            );
           this.props.GetCapabilityPainPoints(this.props.match.params.projectId,projectId,this.state.viewAll);
            
    }

    goToRiskProfileTabAddCapability(){
      this.setState({tabActiveState: 'riskprofile',listOrDomain: 'list', openRiskProfileAddCapabilityPanel:true});
    }

    backClick(){

          let data=this.state.capabilityPainPointsData;
          this.setState({capabilityPainPointsData: [] });

          if(data.length>0){
              
              var innerData=data[0];
              if(innerData.ParentProcessId==0){
                 this.props.history.push('/capability/'+this.props.match.params.projectId);
              }
              else if(innerData.ParentProcessId==null){
                this.props.history.push('/painpoint-initiatives/'+this.props.match.params.projectId+'/'+innerData.ProjectName+'/0');
                this.props.GetCapabilityPainPoints(this.props.match.params.projectId,0,this.state.viewAll);
              }
              else {
                this.props.history.push('/painpoint-initiatives/'+this.props.match.params.projectId+'/'+'L'+(innerData.Level-2)+'.'+innerData.ParentProcessName+'/'+innerData.ParentProcessId);
                this.props.GetCapabilityPainPoints(this.props.match.params.projectId,innerData.ParentProcessId,this.state.viewAll);
              }
             
              
          }
          else

            {
                   var storage=CacheStorage.getStorage();
                   if(storage['painpoint-back-link']==null)
                   this.props.history.push('/capability/'+this.props.match.params.projectId);
                   else{
                    let linker=storage['painpoint-back-link'];
                    linker=linker.split('/');
                    this.props.history.push(storage['painpoint-back-link']); 
                    this.props.GetCapabilityPainPoints(this.props.match.params.projectId,linker[linker.length-1],this.state.viewAll);
                   }
                   
            }

    }
    static getDerivedStateFromProps(nextProps,prevState){
      console.log('pain',nextProps)
      let obj = Object.assign({},prevState);
      if(nextProps.painpointInitiatives.length>0){
        obj.painpointsData = nextProps.painpointInitiatives
      }
      if(nextProps.capabilityPainPoints.length>0){
        obj.capabilityPainPointsData = nextProps.capabilityPainPoints
      }
      return obj
    }


    tabActiveStateClick = (tabState) => {
      this.props.startLoader();
      this.setState({tabActiveState: tabState,listOrDomain: 'list'});
      let selfObj = this;
        setTimeout(function(){              
          selfObj.props.stopLoader();
      },500);
     
  }

  listOrDomain = (tabState) => {
    this.props.startLoader();
      this.setState({listOrDomain: tabState});
      let selfObj = this;
        setTimeout(function(){              
          selfObj.props.stopLoader();
      },1000);
  }

  selectAllRiskProfileCapabilities(domainName){
    //alert(domainName);
    if(this.state.riskprofileCapabilityData.length == 0)
    {
      responseMessage("error", "You have not yet added any capability into your Risk Profile.", "");
    }

    if(this.state.riskprofileCapabilityData.length > 0)
    {
      let riskprofileCapabilityData = this.state.riskprofileCapabilityData;
      let checkallarr = this.state.checkedRiskprofileCapabilityData; 

      riskprofileCapabilityData.map((val,ind) => { 
          
        if(domainName !== null)
        {
           if(val.Domain == domainName)
           {
              if(val.Status == 1)
              {
                let checkallnewId = val.UniqueId;
            
                if(!checkallarr.includes(checkallnewId)){          //checking weather array contain the id
                  checkallarr.push(checkallnewId);               //adding to array because value doesnt exists
                }

              }
            

           }

        }

        if(domainName == null)
        {
          if(val.Status == 1)
          {
            let checkallnewId = val.UniqueId;
          
            if(!checkallarr.includes(checkallnewId)){          //checking weather array contain the id
              checkallarr.push(checkallnewId);               //adding to array because value doesnt exists
            } 

          }
          
          
        }
                           

      })

      
      this.setState({
        checkedRiskprofileCapabilityData: checkallarr
      });

    }
    
  }

  removeCapabilitiesFromRiskProfile(domainName){
    //alert('removeCapabilitiesFromRiskProfile');

    if(this.state.checkedRiskprofileCapabilityData.length == 0)
    {
      responseMessage("error", "No capability selected for remove from Risk Profile.", "");
    }

    if(this.state.checkedRiskprofileCapabilityData.length > 0)
    {
      let exportArr = this.state.unSelectedCapabilityData;
    let unselCapa = [];
    let saveArr = this.state.finalsaveMappingMatrix;
        
        this.state.checkedRiskprofileCapabilityData.map(val=>{
           
            this.state.riskprofileCapabilityData.map(capability=>{
                 
              if(domainName == null)
              {
                if(capability.UniqueId == val)
                {
                    exportArr.push(
                      capability
                    );

                    saveArr.push({
                      'operationType':'delete',
                      'UniqueId':capability.UniqueId
                    })

                    this.saveCheckedUnselectedCapabilityDataProgress(capability.UniqueId);
                }

              }

              if(domainName !== null)
              {
                if(capability.UniqueId == val && capability.Domain == domainName)
                {
                    exportArr.push(
                      capability
                    );

                    saveArr.push({
                      'operationType':'delete',
                      'UniqueId':capability.UniqueId
                    })

                    this.saveCheckedUnselectedCapabilityDataProgress(capability.UniqueId);
                }

              }
                
                
            });

        });

        this.state.riskprofileCapabilityData.map(capability=>{
                 
          if(this.state.checkedRiskprofileCapabilityData.includes(capability.UniqueId))
          {
             
          }else{
            unselCapa.push(
              capability
            )
            
          }
          
      });

        this.setState({
          unSelectedCapabilityData:exportArr,
          checkedUnselectedCapabilityData:[],
          riskprofileCapabilityData:unselCapa,
          riskprofileCapabilityModificationStarted:true,
          finalsaveMappingMatrix:saveArr,
          checkedRiskprofileCapabilityData: []

      });

    }
    
  }

  clearRiskProfileCapabilitiesSelection(domainName){
    //alert('clearRiskProfileCapabilitiesSelection');
    if(domainName == null)
    {
      this.setState({
        checkedRiskprofileCapabilityData:[]
      });

    }

    if(domainName !== null)
    {
      let unselCapaIdDomain = [];
      let unselCapa = [];

      this.state.riskprofileCapabilityData.map(capability=>{

        if(capability.Domain == domainName)
        {
          unselCapaIdDomain.push(
            capability.UniqueId
          )
        }
        
      });

      this.state.checkedRiskprofileCapabilityData.map(capability=>{
                 
        if(unselCapaIdDomain.includes(capability))
        {
           
        }else{
          unselCapa.push(
            capability
          )
          
        }
        
    });

    this.setState({
      checkedRiskprofileCapabilityData:unselCapa
    });

    }
    
  }

  selectAllUnselectedCapabilities(domainName){
    //alert('selectAllUnselectedCapabilities');
    if(this.state.unSelectedCapabilityData.length == 0)
    {
      responseMessage("error", "No capability found.", "");
    }

    if(this.state.unSelectedCapabilityData.length > 0)
    {
      let unSelectedCapabilityData = this.state.unSelectedCapabilityData;
      let checkallarr = this.state.checkedUnselectedCapabilityData; 

      unSelectedCapabilityData.map((val,ind) => { 
          
        if(domainName == null)
        {
          let checkallnewId = val.UniqueId;
          
          if(!checkallarr.includes(checkallnewId)){          //checking weather array contain the id
            checkallarr.push(checkallnewId);               //adding to array because value doesnt exists
          } 

        }

        if(domainName !== null)
        {
          if(val.Domain == domainName)
          {
            let checkallnewId = val.UniqueId;
          
            if(!checkallarr.includes(checkallnewId)){          //checking weather array contain the id
              checkallarr.push(checkallnewId);               //adding to array because value doesnt exists
            } 

          }

        }
                           

      })

      
      this.setState({
        checkedUnselectedCapabilityData: checkallarr
      });

    }
  }

  addCapabilitiesToRiskProfile(domainName){
    //alert('addCapabilitiesToRiskProfile');    
    
    if(this.state.checkedUnselectedCapabilityData.length == 0)
    {
      responseMessage("error", "No capability selected to add to Risk Profile.", "");
    }

    if(this.state.checkedUnselectedCapabilityData.length > 0)
    {
      let exportArr = this.state.riskprofileCapabilityData;
      let unselCapa = [];
      let saveArr = this.state.finalsaveMappingMatrix;

      this.state.checkedUnselectedCapabilityData.map(val=>{
           
        this.state.unSelectedCapabilityData.map(capability=>{

          if(domainName == null)
          {
            if(capability.UniqueId == val)
            {
                exportArr.push(
                  capability
                );

                saveArr.push({
                  'operationType':'new_insert',
                  'UniqueId':capability.UniqueId
                })
            }

          }

          if(domainName !== null)
          {
            if(capability.UniqueId == val && capability.Domain == domainName)
            {
                exportArr.push(
                  capability
                );

                saveArr.push({
                  'operationType':'new_insert',
                  'UniqueId':capability.UniqueId
                })
            }

          }
             
            
            
        });

    });

    this.state.unSelectedCapabilityData.map(capability=>{
             
      if(this.state.checkedUnselectedCapabilityData.includes(capability.UniqueId))
      {
         
      }else{
        unselCapa.push(
          capability
        )
      }
      
  });

    //console.log('---hb---');
    //console.log(exportArr);
    this.setState({
      checkedRiskprofileCapabilityData: [],      
      riskprofileCapabilityData:exportArr,
      unSelectedCapabilityData:unselCapa,
      unselectedCapabilityModificationStarted:true,
      finalsaveMappingMatrix:saveArr,
      checkedUnselectedCapabilityData:[]

  });
      
    }    
        
  }

  clearUnselectedCapabilitiesSelection(domainName){
    //alert('clearUnselectedCapabilitiesSelection');
    if(domainName == null)
    {
      this.setState({
        checkedUnselectedCapabilityData:[]
      });

    }

    if(domainName !== null)
    {
      let unselCapaIdDomain = [];
      let unselCapa = [];

      this.state.unSelectedCapabilityData.map(capability=>{

        if(capability.Domain == domainName)
        {
          unselCapaIdDomain.push(
            capability.UniqueId
          )
        }
        
      });

      this.state.checkedUnselectedCapabilityData.map(capability=>{
                 
        if(unselCapaIdDomain.includes(capability))
        {
           
        }else{
          unselCapa.push(
            capability
          )
          
        }
        
    });

    this.setState({
      checkedUnselectedCapabilityData:unselCapa
    });

    }
    
  }

  async finalsaveRiskProfileselCapability(){

    this.props.startLoader();
    //let mappingMatrixString = this.state.finalsaveMappingMatrix.toString();
    let mappingMatrixString = JSON.stringify(this.state.finalsaveMappingMatrix);
    let projectId = this.state.projectId;

    let data = {
        "projectId":parseInt(projectId),
        'riskProfileJson':mappingMatrixString
    }
    let selfObj = this;
    await axios.post(`${config.laravelBaseUrl}saveRiskProfileselCapability/${projectId}`, data, {
        headers: {
            "authorization": "Bearer " + sessionStorage.getItem("userToken")
        }
    }).then(function(response){
      //CapabilityMappingID
      //selectedVersion           
      //console.log('saved--'); 
      selfObj.props.stopLoader();        
        responseMessage("success", response.data.message, ""); 

        selfObj.setState({
          riskprofileCapabilityData:[],
          unSelectedCapabilityData:[],
          checkedUnselectedCapabilityData:[],
          tabActiveState:'overview',
          listOrDomain: 'list',
          openRiskProfileAddCapabilityPanel:false,
          unselectedCapabilityModificationStarted:false,
          checkedRiskprofileCapabilityData: [],
          riskprofileCapabilityModificationStarted:false,
          unselectedAFlyScoreSortType:'Low',
          unselectedPrioritySortType:'High',
          riskprofileAFlyScoreSortType:'Low',
          riskprofilePrioritySortType:'High',
          finalsaveMappingMatrix:[]
        }, () => {
          selfObj.getOverViewRiskProfile(projectId);
          selfObj.getRiskProfileUnselCapability(projectId);
          selfObj.getRiskProfileselCapability(projectId);
        }); 


    }).catch(function(error) {
      selfObj.props.stopLoader();
      console.log(error);
        //responseMessage("Error", "Something Went Wrong!7", "");
        
    });

  }

  progressDone(){
    let selfObj = this;
    swal({
      title: "Are you sure?",
      text: "Painpoint Initiative will run for new Risk Profile Capabilities and will take approx 30-40 minutes to complete. \n \n Add, delete or update not allowed until completion.\n \n Run Painpoint Initiative now? ",
      icon: "info",
      buttons: [
        'No, go back to edit',
        'Yes, Run Painpoint Initiative'
      ],
      dangerMode: false,
      customClass: 'swal-modal'
    }).then(function(isConfirm) {
      if (isConfirm) {
        selfObj.finalsaveRiskProfileselCapability();
      } else {
        //swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })
  }

  progressCancel(){
    let selfObj = this;
    let projectId = this.state.projectId;

    confirmAlert({
      title: 'Confirmation',
      message: 'All changes since the last run will be lost. Do you want to continue?',
      buttons: [
          {
              label: 'Yes',
              onClick: () => {

                this.setState({
                  riskprofileCapabilityData:[],
                  unSelectedCapabilityData:[],
                  checkedUnselectedCapabilityData:[],
                  tabActiveState:'riskprofile',
                  openRiskProfileAddCapabilityPanel:true,
                  unselectedCapabilityModificationStarted:false,
                  checkedRiskprofileCapabilityData: [],
                  riskprofileCapabilityModificationStarted:false,
                  unselectedAFlyScoreSortType:'Low',
                  unselectedPrioritySortType:'High',
                  riskprofileAFlyScoreSortType:'Low',
                  riskprofilePrioritySortType:'High',
                  finalsaveMappingMatrix:[]
                }, () => {
                  selfObj.getRiskProfileUnselCapability(projectId);
                  selfObj.getRiskProfileselCapability(projectId);
                }); 
    
          }
        },
        {
            label: 'No',
            onClick: () => {

            //
            }
        }
      ]
      });     

  }

  saveCheckedUnselectedCapabilityDataProgress(mappingId)
  {

    //alert(mappingId);
    let checkedUnselectedCapabilityData = this.state.checkedUnselectedCapabilityData;
    const arr = checkedUnselectedCapabilityData; //example array
    const newId = mappingId;                 //new id 

    if(!arr.includes(newId)){          //checking weather array contain the id
        arr.push(newId);               //adding to array because value doesnt exists
    }else{
        arr.splice(arr.indexOf(newId), 1);  //deleting
    }
    checkedUnselectedCapabilityData = arr;
    //console.log(arr);

    this.setState({
        checkedUnselectedCapabilityData: checkedUnselectedCapabilityData        
    });
    //console.log('checkedUnselectedCapabilityData',checkedUnselectedCapabilityData);

  }

  saveCheckedRiskprofileCapabilityDataProgress(mappingId)
  {

    //alert(mappingId);
    let checkedRiskprofileCapabilityData = this.state.checkedRiskprofileCapabilityData;
    const arr = checkedRiskprofileCapabilityData; //example array
    const newId = mappingId;                 //new id 

    if(!arr.includes(newId)){          //checking weather array contain the id
        arr.push(newId);               //adding to array because value doesnt exists
    }else{
        arr.splice(arr.indexOf(newId), 1);  //deleting
    }
    checkedRiskprofileCapabilityData = arr;
    //console.log(arr);

    this.setState({
        checkedRiskprofileCapabilityData: checkedRiskprofileCapabilityData        
    });
    //console.log('checkedUnselectedCapabilityData',checkedUnselectedCapabilityData);

  }

  riskprofileAFlyScoreSort(){
    let riskprofileAFlyScoreSortType = this.state.riskprofileAFlyScoreSortType;
    let riskprofileCapabilityData = this.state.riskprofileCapabilityData;

    riskprofileCapabilityData.sort(function(a, b){
      let x = parseFloat(a.Score);
      let y = parseFloat(b.Score);

      if(riskprofileAFlyScoreSortType == 'Low')
      {
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;

      }

      if(riskprofileAFlyScoreSortType == 'High')
      {
        if (x < y) {return 1;}
        if (x > y) {return -1;}
        return 0;

      }
      
    });

    this.setState({
      riskprofileCapabilityData: riskprofileCapabilityData ,
      riskprofileAFlyScoreSortType: (riskprofileAFlyScoreSortType == 'Low') ? 'High':'Low'       
    });
  }

  riskprofileAFlyScoreSortDomain(domainName){
    let riskprofileAFlyScoreSortType = this.state.riskprofileAFlyScoreSortType;


    let riskprofileCapabilityData = [];
    let otherdomainCapabilityData = [];
    let unSelectedCapabilityDomainviewData = this.groupBy(this.state.riskprofileCapabilityData, 'Domain');
    unSelectedCapabilityDomainviewData.map((row, rowInd)=>{
      if(row.type == domainName)
      {
        riskprofileCapabilityData = row.data;
      }else{        
        otherdomainCapabilityData.push(row.data);
      }
    })
    //console.log(otherdomainCapabilityData);

    riskprofileCapabilityData.sort(function(a, b){
      let x = parseFloat(a.Score);
      let y = parseFloat(b.Score);

      if(riskprofileAFlyScoreSortType == 'Low')
      {
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;

      }

      if(riskprofileAFlyScoreSortType == 'High')
      {
        if (x < y) {return 1;}
        if (x > y) {return -1;}
        return 0;

      }
      
    });

    otherdomainCapabilityData.map((otrow, otrowInd)=>{
      otrow.map((capa,ind)=>{
        riskprofileCapabilityData.push(capa);
        //console.log(capa);
      })
      
    })
    
    this.setState({
      riskprofileCapabilityData: riskprofileCapabilityData ,
      riskprofileAFlyScoreSortType: (riskprofileAFlyScoreSortType == 'Low') ? 'High':'Low'       
    });
  }

  riskprofilePrioritySortDomain(domainName){
    let riskprofilePrioritySortType = this.state.riskprofilePrioritySortType;
        
        
    let riskprofileCapabilityData = [];
    let otherdomainCapabilityData = [];
    let unSelectedCapabilityDomainviewData = this.groupBy(this.state.riskprofileCapabilityData, 'Domain');
    unSelectedCapabilityDomainviewData.map((row, rowInd)=>{
      if(row.type == domainName)
      {
        riskprofileCapabilityData = row.data;
      }else{        
        otherdomainCapabilityData.push(row.data);
      }
    })
    //console.log(otherdomainCapabilityData);

    /* ------- new modification start ------ */

    let riskprofileHighPriorityArr = [];
    let riskprofileMediumPriorityArr = [];
    let riskprofileLowPriorityArr = [];
    let finalretArr = [];
    let nextriskprofilePrioritySortType = '';

    riskprofileCapabilityData.map((row, ind)=>{
      
      if(row.Priority == 'High')
      {
        riskprofileHighPriorityArr.push(row);

      }else if(row.Priority == 'Medium')
      {
        riskprofileMediumPriorityArr.push(row);

      }else if(row.Priority == 'Low')
      {
        riskprofileLowPriorityArr.push(row);

      }else{

      }
    })

    if(riskprofilePrioritySortType == 'High')
    {
      
      finalretArr = [...riskprofileHighPriorityArr, ...riskprofileMediumPriorityArr, ...riskprofileLowPriorityArr];

      nextriskprofilePrioritySortType = 'Low';
      
    }
    /* else if(riskprofilePrioritySortType == 'Medium')
    {
      
      finalretArr = [...riskprofileMediumPriorityArr, ...riskprofileLowPriorityArr, ...riskprofileHighPriorityArr];

      nextriskprofilePrioritySortType = 'Low';
      
    } */
    else if(riskprofilePrioritySortType == 'Low')
    {
      
      finalretArr = [...riskprofileLowPriorityArr, ...riskprofileMediumPriorityArr, ...riskprofileHighPriorityArr];

      nextriskprofilePrioritySortType = 'High';
    }

    riskprofileCapabilityData = [];
    riskprofileCapabilityData = finalretArr;

    /* -------- new modification end ------- */

    /* riskprofileCapabilityData.sort(function(a, b){
        let x = a.Priority.toLowerCase();
        let y = b.Priority.toLowerCase();

      if(riskprofilePrioritySortType == 'Medium')
      {
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;

      }

      if(riskprofilePrioritySortType == 'High')
      {
        if (x < y) {return 1;}
        if (x > y) {return -1;}
        return 0;

      }
      
    }); */

    otherdomainCapabilityData.map((otrow, otrowInd)=>{
      otrow.map((capa,ind)=>{
        riskprofileCapabilityData.push(capa);
        //console.log(capa);
      })
      
    })
    
    this.setState({
      riskprofileCapabilityData: riskprofileCapabilityData ,
      riskprofilePrioritySortType: nextriskprofilePrioritySortType       
    });
  }

  unselectedAFlyScoreSort(){
    let unselectedAFlyScoreSortType = this.state.unselectedAFlyScoreSortType;
    let unSelectedCapabilityData = this.state.unSelectedCapabilityData;

    unSelectedCapabilityData.sort(function(a, b){
      let x = parseFloat(a.Score);
      let y = parseFloat(b.Score);

      if(unselectedAFlyScoreSortType == 'Low')
      {
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;

      }

      if(unselectedAFlyScoreSortType == 'High')
      {
        if (x < y) {return 1;}
        if (x > y) {return -1;}
        return 0;

      }
      
    });

    this.setState({
      unSelectedCapabilityData: unSelectedCapabilityData ,
      unselectedAFlyScoreSortType: (unselectedAFlyScoreSortType == 'Low') ? 'High':'Low'       
    });
  }

  unselectedAFlyScoreSortDomain(domainName){
    let unselectedAFlyScoreSortType = this.state.unselectedAFlyScoreSortType;
    //let unSelectedCapabilityData = this.state.unSelectedCapabilityData;


    let unSelectedCapabilityData = [];
    let otherdomainCapabilityData = [];
    let unSelectedCapabilityDomainviewData = this.groupBy(this.state.unSelectedCapabilityData, 'Domain');
    unSelectedCapabilityDomainviewData.map((row, rowInd)=>{
      if(row.type == domainName)
      {
        unSelectedCapabilityData = row.data;
      }else{        
        otherdomainCapabilityData.push(row.data);
      }
    })
    //console.log(otherdomainCapabilityData);

    unSelectedCapabilityData.sort(function(a, b){
      let x = parseFloat(a.Score);
      let y = parseFloat(b.Score);

      if(unselectedAFlyScoreSortType == 'Low')
      {
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;

      }

      if(unselectedAFlyScoreSortType == 'High')
      {
        if (x < y) {return 1;}
        if (x > y) {return -1;}
        return 0;

      }
      
    });

    otherdomainCapabilityData.map((otrow, otrowInd)=>{
      otrow.map((capa,ind)=>{
        unSelectedCapabilityData.push(capa);
        //console.log(capa);
      })
      
    })
    
    this.setState({
      unSelectedCapabilityData: unSelectedCapabilityData ,
      unselectedAFlyScoreSortType: (unselectedAFlyScoreSortType == 'Low') ? 'High':'Low'       
    });
  }

  unselectedPrioritySortDomain(domainName){
    let unselectedPrioritySortType = this.state.unselectedPrioritySortType;
        
        
    let unSelectedCapabilityData = [];
    let otherdomainCapabilityData = [];
    let unSelectedCapabilityDomainviewData = this.groupBy(this.state.unSelectedCapabilityData, 'Domain');
    unSelectedCapabilityDomainviewData.map((row, rowInd)=>{
      if(row.type == domainName)
      {
        unSelectedCapabilityData = row.data;
      }else{        
        otherdomainCapabilityData.push(row.data);
      }
    })

    /* ---- New modification start ---- */

    let unselHighPriorityArr = [];
    let unselMediumPriorityArr = [];
    let unselLowPriorityArr = [];
    let finalretArr = [];
    let nextunselPrioritySortType = '';

    unSelectedCapabilityData.map((row, ind)=>{
                  
      if(row.Priority == 'High')
      {
        unselHighPriorityArr.push(row);
      }else if(row.Priority == 'Medium')
      {
        unselMediumPriorityArr.push(row);
      }else if(row.Priority == 'Low')
      {
        unselLowPriorityArr.push(row);
      }else{

      }
    })

    if(unselectedPrioritySortType == 'High')
    {
      
      finalretArr = [...unselHighPriorityArr, ...unselMediumPriorityArr, ...unselLowPriorityArr];
      nextunselPrioritySortType = 'Low';
      
    }
    /* else if(unselectedPrioritySortType == 'Medium')
    {
      
      finalretArr = [...unselMediumPriorityArr, ...unselLowPriorityArr, ...unselHighPriorityArr];
      nextunselPrioritySortType = 'Low';
      
    } */
    else if(unselectedPrioritySortType == 'Low')
    {
      
      finalretArr = [...unselLowPriorityArr, ...unselMediumPriorityArr, ...unselHighPriorityArr];
      nextunselPrioritySortType = 'High';
    }

    unSelectedCapabilityData = [];
    unSelectedCapabilityData = finalretArr;

    /* ---- New modification end ---- */
    

    otherdomainCapabilityData.map((otrow, otrowInd)=>{
      otrow.map((capa,ind)=>{
        unSelectedCapabilityData.push(capa);
        //console.log(capa);
      })
      
    })
    
    this.setState({
      unSelectedCapabilityData: unSelectedCapabilityData ,
      unselectedPrioritySortType: nextunselPrioritySortType      
    });
  }

  unselectedPrioritySort(){
    let unselectedPrioritySortType = this.state.unselectedPrioritySortType;
    let unSelectedCapabilityData = this.state.unSelectedCapabilityData;

    let unselHighPriorityArr = [];
    let unselMediumPriorityArr = [];
    let unselLowPriorityArr = [];
    let finalretArr = [];
    let nextunselPrioritySortType = '';

    if(unselectedPrioritySortType == 'High')
    {
      nextunselPrioritySortType = 'Low';
    }
    /* else if(unselectedPrioritySortType == 'Medium')
    {
      nextunselPrioritySortType = 'Low';
    } */
    else if(unselectedPrioritySortType == 'Low')
    {
      nextunselPrioritySortType = 'High';
    }

    unSelectedCapabilityData.map((row, ind)=>{
      
      if(row.Priority == 'High')
      {
        unselHighPriorityArr.push(row);
      }else if(row.Priority == 'Medium')
      {
        unselMediumPriorityArr.push(row);
      }else if(row.Priority == 'Low')
      {
        unselLowPriorityArr.push(row);
      }else{

      }
    })

    if(unselectedPrioritySortType == 'High')
    {
      
      finalretArr = [...unselHighPriorityArr, ...unselMediumPriorityArr, ...unselLowPriorityArr];
      
    }
    /* else if(unselectedPrioritySortType == 'Medium')
    {
      
      finalretArr = [...unselMediumPriorityArr, ...unselLowPriorityArr, ...unselHighPriorityArr];
      
    } */
    else if(unselectedPrioritySortType == 'Low')
    {
      
      finalretArr = [...unselLowPriorityArr, ...unselMediumPriorityArr, ...unselHighPriorityArr];
    }

    //console.log(finalretArr);
    /* let prs = ['High', 'Medium', 'Low'];
    let order = 1;
    if(unselectedPrioritySortType == 'High')
    {
      order = 1;
    }else{
      order = -1;
    }


    unSelectedCapabilityData.sort(function ( a, b ) {
      var x = prs.indexOf(a.Priority);
      var y = prs.indexOf(b.Priority);

      if ( x < y ) return -1 * order;
      if ( x > y ) return 1 * order;
      return 0;
    }); */

    this.setState({
      unSelectedCapabilityData: finalretArr ,
      unselectedPrioritySortType: nextunselPrioritySortType      
    });
  }

  riskprofilePrioritySort(){
    let riskprofilePrioritySortType = this.state.riskprofilePrioritySortType;
    let riskprofileCapabilityData = this.state.riskprofileCapabilityData;

    let riskprofileHighPriorityArr = [];
    let riskprofileMediumPriorityArr = [];
    let riskprofileLowPriorityArr = [];
    let finalretArr = [];
    let nextriskprofilePrioritySortType = '';

    
    riskprofileCapabilityData.map((row, ind)=>{
      
      if(row.Priority == 'High')
      {
        riskprofileHighPriorityArr.push(row);

      }else if(row.Priority == 'Medium')
      {
        riskprofileMediumPriorityArr.push(row);

      }else if(row.Priority == 'Low')
      {
        riskprofileLowPriorityArr.push(row);

      }else{

      }
    })

    if(riskprofilePrioritySortType == 'High')
    {
      
      finalretArr = [...riskprofileHighPriorityArr, ...riskprofileMediumPriorityArr, ...riskprofileLowPriorityArr];

      nextriskprofilePrioritySortType = 'Low';
      
    }
    /* else if(riskprofilePrioritySortType == 'Medium')
    {
      
      finalretArr = [...riskprofileMediumPriorityArr, ...riskprofileLowPriorityArr, ...riskprofileHighPriorityArr];

      nextriskprofilePrioritySortType = 'Low';
      
    } */
    else if(riskprofilePrioritySortType == 'Low')
    {
      
      finalretArr = [...riskprofileLowPriorityArr, ...riskprofileMediumPriorityArr, ...riskprofileHighPriorityArr];

      nextriskprofilePrioritySortType = 'High';
    }

    this.setState({
      riskprofileCapabilityData: finalretArr ,
      riskprofilePrioritySortType: nextriskprofilePrioritySortType      
    });
  }



render(){

  //console.log(this.state.tabActiveState+'-'+this.state.listOrDomain+'-'+this.state.riskprofileCapabilityData.length);

  let projectOptions = [];
        if(this.props.capabilityModellingProjectdropdownData.length > 0 ) {
            projectOptions = this.props.capabilityModellingProjectdropdownData.map((project, i) => {
                let uniqId = project.ProjectName.trim() + '-'+ i;
                return (
                    <option value={project.DecompositionProjectID} key={uniqId}>{ project.ProjectName }</option>
                )
            })
        }


  /* ------ overview table starts here ----- */
  
  let overviewRiskprofileSerialNo = 0;
  let overviewRiskprofiletableData = this.state.overviewRiskprofileData.length>0?this.state.overviewRiskprofileData.map((overviewriskprofile=>{
    overviewRiskprofileSerialNo++;
    return(
      <tr style={{display:"table" ,width:"100%"}}>
        <td style={{width:'7%' }}>{overviewRiskprofileSerialNo}</td>
        <td style={{width:'15%' }}>{overviewriskprofile.Capability?overviewriskprofile.Capability:''}</td>
        <td style={{width:'15%' }}>{overviewriskprofile.Function?overviewriskprofile.Function:''}</td>
        <td style={{width:'15%' }}>{overviewriskprofile.Phase?overviewriskprofile.Phase:''}</td>
        <td style={{width:'10%' }}><a style={{"cursor":'pointer' }} onClick={(e) => this.toggle(e,'Root Cause', overviewriskprofile.RootCause)} data-toggle="modal" data-target="#overviewmodal">{<img src={require('./../../common/images/painpoint_initiative/area_graph.png')} className="icon-img" alt="List" />}{<img src={(overviewriskprofile.Status == 0)? require('./../../common/images/painpoint_initiative/in_progress.png'):require('./../../common/images/painpoint_initiative/progress_done.png')} className="icon-img" alt="List" />}</a></td>
        <td style={{width:'10%' }}><a style={{"cursor":'pointer' }} onClick={(e) => this.toggle(e,'Solution', overviewriskprofile.Solution)} data-toggle="modal" data-target="#overviewmodal">{<img src={require('./../../common/images/painpoint_initiative/solution.png')} className="icon-img" alt="List" />}{<img style={{"margin-left":"2px" }} src={(overviewriskprofile.Status == 0)? require('./../../common/images/painpoint_initiative/in_progress.png'):require('./../../common/images/painpoint_initiative/progress_done.png')} className="icon-img" alt="List" />}</a></td>
        <td style={{width:'10%' }}><a style={{"cursor":'pointer' }} onClick={(e) => this.toggle(e,'KPI', overviewriskprofile.KPI)} data-toggle="modal" data-target="#overviewmodal">{<img src={require('./../../common/images/painpoint_initiative/kpi.png')} className="icon-img" alt="List" />}{<img style={{"margin-left":"2px" }} src={(overviewriskprofile.Status == 0)? require('./../../common/images/painpoint_initiative/in_progress.png'):require('./../../common/images/painpoint_initiative/progress_done.png')} className="icon-img" alt="List" />}</a></td>
        <td style={{width:'15%' }}>{overviewriskprofile.ModifiedDate?overviewriskprofile.ModifiedDate:''}</td>
        <td style={{width:'15%'}}><div style={{width:'100px', "display":"flex", "justify-content":"flex-end" }}><a style={{"cursor":'pointer' }} onClick={(overviewriskprofile.Status == 0)?null:()=>this.setState({isOpenEditModal:true,   editRootCauseData:(overviewriskprofile.RootCause) ? overviewriskprofile.RootCause:'',
          editSolutionData:(overviewriskprofile.Solution)? overviewriskprofile.Solution:'',
          editKPIData:(overviewriskprofile.KPI)?overviewriskprofile.KPI:'',
          editdecompositionprocesslevelid_1:overviewriskprofile.DecompositionProcessLevel1ID
          })} >{<img style={{"margin-left":"2px" }} src={(overviewriskprofile.Status == 0)? require('./../../common/images/painpoint_initiative/edit_inactive.png'):require('./../../common/images/painpoint_initiative/edit.png')} className="icon-img" alt="List" />}</a></div></td>
      </tr>
    )
    })) :""  ; 
  
  /* overview ends here ------------------ */



        let riskprofilecapabilitySerialNo = 0;
        let riskprofilecapabilitytableData = this.state.riskprofileCapabilityData.length>0?this.state.riskprofileCapabilityData.map((riskprofilecapa=>{
          riskprofilecapabilitySerialNo++;
          return(
        
            <tr style={{display:"table" ,width:"100%"}}>
              {(this.state.openRiskProfileAddCapabilityPanel == true) ? <><td style={{width:'5%', "padding":"14px 7px"}}>
            <input type="checkbox" 
              style={{"width":"28px", "height":"28px" }}
              disabled={(riskprofilecapa.Status==1)?false:true}
              name="check_riskprofile_capability"                                
              checked={(riskprofilecapa.Status==1)?(this.state.checkedRiskprofileCapabilityData.includes(riskprofilecapa.UniqueId)) ? true : false:false }
              value={riskprofilecapa.UniqueId}
              title="Select"
              onChange={(riskprofilecapa.Status==1)?(e) => this.saveCheckedRiskprofileCapabilityDataProgress(riskprofilecapa.UniqueId):null}
            />
            </td></> : null}
            
            <td style={{width:'10%', "padding":"14px 7px", "vertical-align":"middle" }} className={(riskprofilecapa.Status==0)?"processtdcolor":""}>{riskprofilecapabilitySerialNo>0?riskprofilecapabilitySerialNo:''}</td>
            <td style={{width:'20%', "vertical-align":"middle" }} className={(riskprofilecapa.Status==0)?"processtdcolor":""}>{riskprofilecapa.Capability?riskprofilecapa.Capability:''}</td>
            <td style={{width:'20%', "vertical-align":"middle" }} className={(riskprofilecapa.Status==0)?"processtdcolor":""}>{riskprofilecapa.Domain?riskprofilecapa.Domain:''}</td>
            <td style={{width:'20%', "vertical-align":"middle" }} className={(riskprofilecapa.Status==0)?"processtdcolor":""}>{riskprofilecapa.Score?riskprofilecapa.Score:''}</td>
            <td style={{width:'20%', "vertical-align":"middle" }} className={(riskprofilecapa.Status==0)?"processtdcolor":""}>{riskprofilecapa.Priority?riskprofilecapa.Priority:''}</td>
            </tr>
          )
          })) :""  ; 


let unselectedcapabilitySerialNo = 0;
let unselectedcapabilitytableData = this.state.unSelectedCapabilityData.length>0?this.state.unSelectedCapabilityData.map((unselcapa=>{
  unselectedcapabilitySerialNo++;
  return(

    <tr style={{display:"table" ,width:"100%"}}>
      {(this.state.openRiskProfileAddCapabilityPanel == true) ? <><td style={{width:'5%', "padding":"14px 7px"}}>
    <input type="checkbox" 
      style={{"width":"28px", "height":"28px" }}
      name="check_unselected_capability"                                
      checked={(this.state.checkedUnselectedCapabilityData.includes(unselcapa.UniqueId)) ? true : false }
      value={unselcapa.UniqueId}
      title="Select"
      onChange={(e) => this.saveCheckedUnselectedCapabilityDataProgress(unselcapa.UniqueId)}
    />
    </td></>:null}
    
    <td style={{width:'10%', "padding":"14px 7px", "vertical-align":"middle" }}>{unselectedcapabilitySerialNo>0?unselectedcapabilitySerialNo:''}</td>
    <td style={{width:'20%', "vertical-align":"middle" }}>{unselcapa.Capability?unselcapa.Capability:''}</td>
    <td style={{width:'20%', "vertical-align":"middle" }}>{unselcapa.Domain?unselcapa.Domain:''}</td>
    <td style={{width:'20%', "vertical-align":"middle" }}>{unselcapa.Score?unselcapa.Score:''}</td>
    <td style={{width:'20%', "vertical-align":"middle" }}>{unselcapa.Priority?unselcapa.Priority:''}</td>
    </tr>
  )
  })) :""  ; 

  /* ------------------- Unselected Domain View start------------------- */
  let unSelectedCapabilityDomainviewData = this.groupBy(this.state.unSelectedCapabilityData, 'Domain');
  //console.log(unSelectedCapabilityDomainviewData);

  let unselectedcapabilityDomainviewtableData = unSelectedCapabilityDomainviewData.map((row, rowInd)=>{
    //console.log(row);
    let subInc=0;
      let subcapdata = row.data.map((capa,ind)=>{
        subInc++;
        return(
          <>
            <tr style={{display:"table" ,width:"100%"}}>
              <td style={{width:'5%', "padding":"14px 7px"}}>
              <input type="checkbox" 
                style={{"width":"28px", "height":"28px" }}
                name="check_unselected_capability"                                
                checked={(this.state.checkedUnselectedCapabilityData.includes(capa.UniqueId)) ? true : false }
                value={capa.UniqueId}
                title="Select"
                onChange={(e) => this.saveCheckedUnselectedCapabilityDataProgress(capa.UniqueId)}
              />
              </td>
              <td style={{width:'10%', "padding":"14px 7px", "vertical-align":"middle" }}>{subInc}</td>
              <td style={{width:'20%', "vertical-align":"middle" }}>{capa.Capability?capa.Capability:''}</td>
              <td style={{width:'20%', "vertical-align":"middle" }}>{capa.Domain?capa.Domain:''}</td>
              <td style={{width:'20%', "vertical-align":"middle" }}>{capa.Score?capa.Score:''}</td>
              <td style={{width:'20%', "vertical-align":"middle" }}>{capa.Priority?capa.Priority:''}</td>
          </tr>
          </>
          );
          
      });
    return(
      <>      
      <div class="d-flex justify-content-between align-items-center heatmap-header">
                 <div style={{width:'60%',"padding-left":"10px"}}>
                  <h2>{row.type}</h2>
                 </div>
            </div>
      <table className="table mt-4 table-fixx-block" style={{"min-height":"150px"}}>
                    <thead className="thead-dark">                      
                    <tr style={{display:"table" ,width:"100%"}}>
                      <th style={{width:'5%', "padding":"14px 7px", "height":"62px" }}>
                      {(this.state.unSelectedCapabilityData.length>0 && this.state.checkedUnselectedCapabilityData.length>0 && this.checkdomainviewcapabilityexists('unselected',row.type)) ? <>
                        <a href="javascript:void(0);"  data-toggle="dropdown" role="button" aria-expanded="false" style={{"color":"#26262C"}}>{<img src={require('./../../common/images/painpoint_initiative/More.png')} className="icon-img" alt="List" />}</a>
                      <ul class="dropdown-menu" role="menu">
                        <li style={{"text-align":"right", "padding-right":"10px", "cursor":"pointer" }}><span><i className='fa'>&#xf00d;</i></span></li>                            
                          <li style={{"border-bottom":"1px solid #cecece", "padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.selectAllUnselectedCapabilities(row.type)}>Select All</a></li>
                          <li style={{"border-bottom":"1px solid #cecece", "padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.addCapabilitiesToRiskProfile(row.type)}>Add to Risk Profile</a></li>
                          <li style={{"padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.clearUnselectedCapabilitiesSelection(row.type)}>Clear Selection</a></li>                           
                        </ul>
                      </>:null}                        
                      </th>
                        <th style={{width:'10%', "padding":"14px 7px" }}>SL No.</th>
                        <th style={{width:'20%' }}>Capability</th>
                        <th style={{width:'20%' }}>Domain</th>
                        <th style={{width:'20%' }}>AFly Score <a href="javascript:void(0);" onClick={()=>this.unselectedAFlyScoreSortDomain(row.type)} style={{"color":"#000000"}}><i className='fa fa-sort' style={{"font-size":"14px", "color": "#000000"}}></i></a></th>
                        <th style={{width:'20%' }}>Priority <a href="javascript:void(0);" onClick={()=>this.unselectedPrioritySortDomain(row.type)} style={{"color":"#000000"}}><i className='fa fa-sort' style={{"font-size":"14px", "color": "#000000"}}></i></a></th>
                      </tr>
                    </thead>
                    <tbody>
                    {subcapdata}
                    </tbody>
                  </table>
      
      </>
    );
  });


  /* ------------------------ Unselected domain view ends here ------------------ */

    /* ------------------- Risk Profile Domain View start------------------- */
    let riskprofileCapabilityDomainviewData = this.groupBy(this.state.riskprofileCapabilityData, 'Domain');
    //console.log(riskprofileCapabilityDomainviewData);
  
    let riskprofilecapabilityDomainviewtableData = riskprofileCapabilityDomainviewData.map((row, rowInd)=>{
      //console.log(row);
      let subInc=0;
        let subcapdata = row.data.map((capa,ind)=>{
          subInc++;
          return(
            <>
              <tr style={{display:"table" ,width:"100%"}}>
                <td style={{width:'5%', "padding":"14px 7px"}}>
                <input type="checkbox" 
              style={{"width":"28px", "height":"28px" }}
              disabled={(capa.Status==1)?false:true}
              name="check_riskprofile_capability"
              checked={(capa.Status==1)?(this.state.checkedRiskprofileCapabilityData.includes(capa.UniqueId)) ? true : false:false }
              value={capa.UniqueId}
              title="Select"
              onChange={(capa.Status==1)?(e) => this.saveCheckedRiskprofileCapabilityDataProgress(capa.UniqueId):null}
            />
                </td>
                <td style={{width:'10%', "padding":"14px 7px", "vertical-align":"middle" }} className={(capa.Status==0)?"processtdcolor":""}>{subInc}</td>
                <td style={{width:'20%', "vertical-align":"middle" }} className={(capa.Status==0)?"processtdcolor":""}>{capa.Capability?capa.Capability:''}</td>
                <td style={{width:'20%', "vertical-align":"middle" }} className={(capa.Status==0)?"processtdcolor":""}>{capa.Domain?capa.Domain:''}</td>
                <td style={{width:'20%', "vertical-align":"middle" }} className={(capa.Status==0)?"processtdcolor":""}>{capa.Score?capa.Score:''}</td>
                <td style={{width:'20%', "vertical-align":"middle" }} className={(capa.Status==0)?"processtdcolor":""}>{capa.Priority?capa.Priority:''}</td>
            </tr>
            </>
            );
            
        });
      return(
        <>      
        <div class="d-flex justify-content-between align-items-center heatmap-header">
                   <div style={{width:'60%',"padding-left":"10px"}}>
                    <h2>{row.type}</h2>
                   </div>
              </div>
        <table className="table mt-4 table-fixx-block" style={{"min-height":"150px"}}>
        <thead className="thead-dark">                      
                      <tr style={{display:"table" ,width:"100%"}}>
                      <th style={{width:'5%', "padding":"14px 7px", "height":"62px" }}>
                      {(this.state.riskprofileCapabilityData.length>0 && this.state.checkedRiskprofileCapabilityData.length>0 && this.checkdomainviewcapabilityexists('riskprofile',row.type)) ? <>
                        <a href="javascript:void(0);"  data-toggle="dropdown" role="button" aria-expanded="false" style={{"color":"#26262C"}}>{<img src={require('./../../common/images/painpoint_initiative/More.png')} className="icon-img" alt="List" />}</a>
                      <ul class="dropdown-menu" role="menu">
                        <li style={{"text-align":"right", "padding-right":"10px", "cursor":"pointer" }}><span><i className='fa'>&#xf00d;</i></span></li>                            
                          <li style={{"border-bottom":"1px solid #cecece", "padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.selectAllRiskProfileCapabilities(row.type)}>Select All</a></li>
                          <li style={{"border-bottom":"1px solid #cecece", "padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.removeCapabilitiesFromRiskProfile(row.type)}>Remove from Risk Profile</a></li>
                          <li style={{"padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.clearRiskProfileCapabilitiesSelection(row.type)}>Clear Selection</a></li>                           
                        </ul>
                        </>:null}                        
                      </th>
                        <th style={{width:'10%', "padding":"14px 7px" }}>SL No.</th>
                        <th style={{width:'20%' }}>Capability</th>
                        <th style={{width:'20%' }}>Domain</th>
                        <th style={{width:'20%' }}>AFly Score <a href="javascript:void(0);" onClick={()=>this.riskprofileAFlyScoreSortDomain(row.type)} style={{"color":"#000000"}}><i className='fa fa-sort' style={{"font-size":"14px", "color": "#000000"}}></i></a></th>
                        <th style={{width:'20%' }}>Priority <a href="javascript:void(0);" onClick={()=>this.riskprofilePrioritySortDomain(row.type)} style={{"color":"#000000"}}><i className='fa fa-sort' style={{"font-size":"14px", "color": "#000000"}}></i></a></th>
                      </tr>
                    </thead>
                      <tbody>
                      {subcapdata}
                      </tbody>
                    </table>
        
        </>
      );
    });
  
  
    /* ------------------------ Risk Profile domain view ends here ------------------ */

 /* ------ activity table starts here ----- */
  
 let activityRiskprofileSerialNo = 0;
 let activityRiskprofiletableData = this.state.activityRiskprofileData.length>0?this.state.activityRiskprofileData.map((activityriskprofile=>{
   activityRiskprofileSerialNo++;
   return(
     <tr style={{display:"table" ,width:"100%"}}>
       <td style={{width:'7%' }}>{activityRiskprofileSerialNo}</td>
       <td style={{width:'25%' }}>{activityriskprofile.Capability?activityriskprofile.Capability:''}</td>
       <td style={{width:'15%' }}>{activityriskprofile.ModifiedBy?activityriskprofile.ModifiedBy:''}</td>
       <td style={{width:'15%' }}>{activityriskprofile.ModifiedDate?activityriskprofile.ModifiedDate:''}</td>
     </tr>
   )
   })) :""  ; 
 
 /* activity ends here ------------------ */    
  
return(
            <>
            <DashboardHeader key="dashboard-header"></DashboardHeader>,
            <div id="wrapper" key="body-wrapper" style={{marginTop:"30px"}}>
                <DashboardSidebar></DashboardSidebar>
                <Loader loading={this.state.loading}/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <ol className="breadcrumb dashbread">
                            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                            <li className="breadcrumb-item">Capability Modeling</li>
                            <li className="breadcrumb-item active">Painpoint Initiative</li>
                            <li className="breadcrumb-menu d-md-down-none ml-auto">
                                <span className="position-relative helpwrap">
                                    <a href="#" className="helpicon dropdown-toggle" id="helpBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="far fa-question-circle"></i></a>
                                    <div className="dropdown-menu" aria-labelledby="helpBtn">
                                        <p>Lorem Ipsum Dolor</p>
                                    </div>
                                </span>
                            </li>
                            <li className="breadcrumb-menu d-md-down-none">
                                {<img src={require('./../../common/images/diva-icon.png')} className="logo-img" alt="Logo" />}
                                <a className="btn powered p-0" href="#">
                                    <i className="icon-graph"></i> &nbsp;
                                <i>Powered by Amploglobal</i>
                                </a>
                            </li>
                        </ol>
                        <PainPointsWrapper>
                        <div class="container-fluid container-dashboard">
          <div class="heatmap-content">
          <Row className="business-decomposition-sec mt-4">
            <Col xl="12" className="pr-0">
              <div className="model-list">
              <Label className="model-label">Capability Modeling Project:</Label>                        
              </div>
            </Col>
            <Col xl="12" className="pr-0">
            <select className="form-control" style={{width:"250px"}} name="project" disabled value={this.props.match.params.projectId}>
                <option value={0}>Select Project</option>
                { projectOptions }
            </select>
            </Col>
          </Row>
            {/* <div class="d-flex justify-content-between align-items-center heatmap-header">
                 <div style={{width:'60%'}}>
                  <h2>{this.props.match.params.projectName}</h2>
                 </div>  
                 <div style={{width:'40%'}} align="right" >
                  
                  </div>
            </div> */}
            <div class="d-flex justify-content-between align-items-center heatmap-header">                  
                 <div style={{width:'40%'}} >
                  <Link onClick={this.backClick} className="backlink"><button style={{padding:7, "margin-right":"10px" }} type="button" className="btn btn-outline-primary"><i className='fa fa-angle-left'></i> Back</button></Link>
                 </div>
            </div>
            <div class="d-flex justify-content-between align-items-center heatmap-header">
                 <div style={{width:'60%'}}>
                  <h2>Painpoint Initiative</h2>
                 </div>  
                 <div style={{width:'40%'}} align="right" >
                  
                  </div>
            </div>

            <div class="d-flex justify-content-start align-items-center heatmap-header" style={{width:"400px" }}>                  
                 <div style={{width:'90px'}} >
                  <Link onClick={this.tabActiveStateClick.bind(this, 'overview')} className="backlink"><button type="button" className={(this.state.tabActiveState == 'overview')? "btn btn-primary":"btn btn-outline-primary-tab"} style={{padding:7, "margin-right":"10px", "line-height": "1" }}>Overview</button></Link>
                 </div>
                 <div style={{width:'107px'}} >
                  <Link onClick={this.tabActiveStateClick.bind(this, 'riskprofile')} className="backlink"><button type="button" className={(this.state.tabActiveState == 'riskprofile')? "btn btn-primary":"btn btn-outline-primary-tab"} style={{padding:7, "margin-right":"10px", "line-height": "1" }}>Risk Profile</button></Link>
                 </div>
                 <div style={{width:'100px'}} >
                  <Link onClick={this.tabActiveStateClick.bind(this, 'activity')} className="backlink"><button type="button" className={(this.state.tabActiveState == 'activity')? "btn btn-primary":"btn btn-outline-primary-tab"} style={{padding:7, "margin-right":"10px", "line-height": "1" }}>Activity</button></Link>
                 </div>
            </div>

           
          <div class="card table-section mt-0 mb-5">
            <div class="card-body">            

{(this.state.tabActiveState == 'overview' && this.state.listOrDomain =='list' && this.state.riskprofileCapabilityData.length == 0)? <>
           
            <div class="d-flex justify-content-start mt-3">

              {/* <div className={(this.state.listOrDomain =='list')? "ml-4 mr-2":"ml-4 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12 align-items-center " style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={require('./../../common/images/painpoint_initiative/sandwitch-icon.png')} className="icon-img" alt="List" />}</span></div></a></div>

              <div className={(this.state.listOrDomain =='domain')? "ml-2 mr-2":"ml-2 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={require('./../../common/images/painpoint_initiative/domain-icon.png')} className="icon-img" alt="Domain" />}</span></div></a></div>  */}

              <div className="ml-auto mr-2" ><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"10px" }} type="button" disabled className="btn btn btn-disabled"><i className='fa fa-edit'></i> Edit Risk Profile</button></div></a></div>
            
            </div>
            
            <div class="col-sm-12 heatmap-table table-responsive">
                  <table class="table mt-4 table-fixx-block">
                    <thead class="thead-dark">                      
                      <tr style={{display:"table" ,width:"100%"}}>
                        <th style={{width:'5%' }}>SL NO.</th>
                        <th style={{width:'15%' }}>Capability</th>
                        <th style={{width:'10%' }}>Function</th>
                        <th style={{width:'10%' }}>Phase</th>
                        <th style={{width:'20%' }}>Root Cause</th>
                        <th style={{width:'20%' }}>Solution </th>
                        <th style={{width:'20%' }}>KPI </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      <td colSpan={7} style={{width:'1032px', paddingTop:"35px" }}>You have not yet added any capability into your Risk Profile.</td>
                      </tr>
                    </tbody>
                  </table>
            <div class="d-flex justify-content-between align-items-center" style={{"padding":"0px 25px 36px 13px" }}>                  
                 <div style={{width:'40%'}} >
                  <Link onClick={this.goToRiskProfileTabAddCapability.bind(this)} className="backlink"><button style={{"padding":"3px 7px", "margin-right":"10px" }} type="button" className="btn btn-outline-primary"><i className='fa fa-plus'></i> Add Capabilities</button></Link>
                 </div>
            </div>
                </div>
            </>:null}

            

{(this.state.tabActiveState == 'overview' && this.state.listOrDomain =='list' && this.state.riskprofileCapabilityData.length>0)? <>

<div class="d-flex justify-content-start mt-3">

  {/* <div className={(this.state.listOrDomain =='list')? "ml-4 mr-2":"ml-4 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12 align-items-center " style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={require('./../../common/images/painpoint_initiative/sandwitch-icon.png')} className="icon-img" alt="List" />}</span></div></a></div>

  <div className={(this.state.listOrDomain =='domain')? "ml-2 mr-2":"ml-2 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={require('./../../common/images/painpoint_initiative/domain-icon.png')} className="icon-img" alt="Domain" />}</span></div></a></div>  */}

  <div className="ml-auto mr-2" ><a href="javascript:void(0);" onClick={()=>this.setState({tabActiveState:'riskprofile',openRiskProfileAddCapabilityPanel:true})} style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"10px" }} type="button" className="btn btn btn-outline-primary"><i className='fa fa-edit'></i> Edit Risk Profile</button></div></a></div>

</div>

    <div class="col-sm-12 heatmap-table table-responsive">
          <table class="table mt-4 table-fixx-block">
            <thead class="thead-dark">                      
              <tr style={{display:"table" ,width:"100%"}}>
              <th style={{width:'5%' }}>SL NO.</th>
              <th style={{width:'15%' }}>Capability</th>
              <th style={{width:'15%' }}>Function</th>
              <th style={{width:'15%' }}>Phase</th>
              <th style={{width:'10%' }}>Root Cause</th>
              <th style={{width:'10%' }}>Solution </th>
              <th style={{width:'10%' }}>KPI </th>
              <th style={{width:'15%' }}>Last Updated </th>
              <th style={{width:'15%' }}><div style={{width:'100px', "display":"flex", "justify-content":"flex-end" }}>EDIT</div></th>
              </tr>
            </thead>
            <tbody>
            {this.state.overviewRiskprofileData.length>0?overviewRiskprofiletableData:<tr>
              <td colSpan={9} style={{width:'1032px', paddingTop:"35px" }}>You have not yet added any capability into your Risk Profile.</td>
              </tr>} 
            </tbody>
          </table>
        </div>

        <ModalPopup isOpen={this.state.isOpen} toggle={this.toggle.bind(this)} title={this.state.overviewModalTitle}>
          <div className="row mt-2 mb-4">
            <div className="col-sm-12" dangerouslySetInnerHTML={{__html: (this.state.overviewModalDesc)?this.state.overviewModalDesc: 'No data found.'}}/>               
                       
          </div>       
          <div className="modal-footer">
          <button type="button" className="btn btn-secondary ml-3"  onClick={(e) => this.toggle(e,null,null)}>Close</button>
          </div> 
      </ModalPopup>

{/* -------------------------------  Edit Modal -------------------------------------------  */}

      <ModalPopup className="modal-lg" isOpen={this.state.isOpenEditModal} toggle={()=>this.setState({isOpenEditModal:false,editRootCauseData:'',editSolutionData:'',editKPIData:'',editdecompositionprocesslevelid_1:''})} title="Edit Painpoint Initiative">
      <div className="row mt-2 mb-4">
      <div className="col-sm-12">
          <div className="form-group">
              <label>Root Cause <span className="text-danger">*</span></label>
              <textarea className="form-control" name="edit_rootcause" onChange={(e) => this.inputChangeHandler(e)} style={{'height':'155px'}}>{this.state.editRootCauseData}</textarea>
              {this.state.editRootCauseData.length < 2 ?<span className="text-danger">Root cause is required.</span>:""}
          </div>
          </div>        
          <div className="col-sm-12">
          <div className="form-group">
              <label>Solution <span className="text-danger">*</span></label>
              <textarea className="form-control" name="edit_solution" onChange={(e) => this.inputChangeHandler(e)} style={{'height':'155px'}}>{this.state.editSolutionData}</textarea>
              {this.state.editSolutionData.length < 2 ?<span className="text-danger">Solution is required.</span>:""}
          </div>
          </div>        
          <div className="col-sm-12">
          <div className="form-group">
              <label>KPI <span className="text-danger">*</span></label>
              <textarea className="form-control" name="edit_kpi" onChange={(e) => this.inputChangeHandler(e)} style={{'height':'155px'}}>{this.state.editKPIData}</textarea>
              {this.state.editKPIData.length < 2 ?<span className="text-danger">KPI is required.</span>:""}
          </div>       
          </div>      
      </div>                
          <div className="modal-footer">
          <button type="button" className="btn btn-secondary ml-3"  onClick={()=>this.setState({isOpenEditModal:false,editRootCauseData:'',editSolutionData:'',editKPIData:'',editdecompositionprocesslevelid_1:''})}>Cancel</button>
          {(this.state.editRootCauseData.length > 2 && this.state.editSolutionData.length > 2 && this.state.editKPIData.length > 2)?<><button class="btn btn-primary ml-3" onClick={(e) => this.saveRiskProfilPainPoint()}> Save</button></>:<><button class="btn btn-secondary ml-3"> Save</button></>}
          
          </div> 
      </ModalPopup>
    </>:null}

          
            
{(this.state.tabActiveState == 'riskprofile' && this.state.listOrDomain =='list' && this.state.riskprofileCapabilityData.length == 0 && this.state.openRiskProfileAddCapabilityPanel == false)? <>

<div class="d-flex justify-content-start mt-3">

  {/* <div className={(this.state.listOrDomain =='list')? "ml-4 mr-2":"ml-4 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12 align-items-center " style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={require('./../../common/images/painpoint_initiative/sandwitch-icon.png')} className="icon-img" alt="List" />}</span></div></a></div>

  <div className={(this.state.listOrDomain =='domain')? "ml-2 mr-2":"ml-2 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={require('./../../common/images/painpoint_initiative/domain-icon.png')} className="icon-img" alt="Domain" />}</span></div></a></div>  */}

  <div className="ml-auto mr-2" ><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"10px" }} type="button" disabled className="btn btn btn-disabled"><i className='fa fa-edit'></i> Edit Risk Profile</button></div></a></div>

</div>

    <div className="col-sm-12 heatmap-table table-responsive">
          <table className="table mt-4 table-fixx-block">
            <thead className="thead-dark">                      
              <tr style={{display:"table" ,width:"100%"}}>
              <th style={{width:'15%' }}>SL No.</th>
                <th style={{width:'20%' }}>Capability</th>
                <th style={{width:'20%' }}>Domain</th>
                <th style={{width:'20%' }}>AFly Score</th>
                <th style={{width:'20%' }}>Priority</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{display:"table" ,width:"100%"}}>
              <td colSpan={5} style={{width:'1032px', paddingTop:"35px" }}>You have not yet added any capability into your Risk Profile.</td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-between align-items-center" style={{"padding":"0px 25px 36px 13px" }}>                  
          <div style={{width:'40%'}} >
          <a href="javascript:void(0);" style={{"color":"#26262C"}} onClick={()=>this.setState({openRiskProfileAddCapabilityPanel:true})}><button style={{"padding":"3px 7px", "margin-right":"10px" }} type="button" className="btn btn-outline-primary"><i className='fa fa-plus'></i> Add Capabilities</button></a>
          </div>
    </div>
        </div>
            </>:null}


{(this.state.tabActiveState == 'riskprofile' && this.state.listOrDomain =='list' && this.state.riskprofileCapabilityData.length > 0 && this.state.openRiskProfileAddCapabilityPanel == false)? <>

<div class="d-flex justify-content-start mt-3">

{/* <div className={(this.state.listOrDomain =='list')? "ml-4 mr-2":"ml-4 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12 align-items-center " style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={require('./../../common/images/painpoint_initiative/sandwitch-icon.png')} className="icon-img" alt="List" />}</span></div></a></div>

  <div className={(this.state.listOrDomain =='domain')? "ml-2 mr-2":"ml-2 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={require('./../../common/images/painpoint_initiative/domain-icon.png')} className="icon-img" alt="Domain" />}</span></div></a></div>  */}

<div className="ml-auto mr-2" ><a href="javascript:void(0);" onClick={()=>this.setState({openRiskProfileAddCapabilityPanel:true})} style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"10px" }} type="button" className="btn btn btn-outline-primary"><i className='fa fa-edit'></i> Edit Risk Profile</button></div></a></div>
  

</div>

            <div class="d-flex justify-content-between align-items-center heatmap-header">
                 <div style={{width:'60%',"padding-left":"85px"}}>
                  <h2>Risk Profile Capabilities</h2>
                 </div>  
                 <div style={{width:'40%'}} align="right" >
                  
                  </div>
            </div>
            <div className="col-sm-12 heatmap-table table-responsive">
                  <table className="table mt-4 table-fixx-block" style={{"min-height":(this.state.riskprofileCapabilityData.length>0)?"240px":"100px"}}>
                    <thead className="thead-dark">                      
                      <tr style={{display:"table" ,width:"100%"}}>
                        <th style={{width:'10%', "padding":"14px 7px" }}>SL No.</th>
                        <th style={{width:'20%' }}>Capability</th>
                        <th style={{width:'20%' }}>Domain</th>
                        <th style={{width:'20%' }}>AFly Score</th>
                        <th style={{width:'20%' }}>Priority</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.riskprofileCapabilityData.length>0?riskprofilecapabilitytableData:<tr>
                      <td colSpan={5} style={{width:'1032px', paddingTop:"35px" }}>You have not yet added any capability into your Risk Profile.</td>
                      </tr>}                      
                    </tbody>
                  </table>
                </div>
                <div class="d-flex justify-content-between align-items-center heatmap-header">
                 <div style={{width:'60%',"padding-left":"85px"}}>
                  <h2>Unselected Capabilities</h2>
                 </div>  
                 <div style={{width:'40%'}} align="right" >
                  
                  </div>
            </div>
            <div className="col-sm-12 heatmap-table table-responsive" style={{"margin-bottom":"60px"}}>
                  <table className="table mt-4 table-fixx-block" style={{"min-height":"300px"}}>
                    <thead className="thead-dark">                      
                    <tr style={{display:"table" ,width:"100%"}}>
                        <th style={{width:'10%', "padding":"14px 7px" }}>SL No.</th>
                        <th style={{width:'20%' }}>Capability</th>
                        <th style={{width:'20%' }}>Domain</th>
                        <th style={{width:'20%' }}>AFly Score</th>
                        <th style={{width:'20%' }}>Priority</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.unSelectedCapabilityData.length>0?unselectedcapabilitytableData:<tr>
                        <td colSpan={5} style={{width:'1032px', paddingTop:"35px" }}>No Capability Available</td>
                      </tr>}
                    </tbody>
                  </table>
                </div>
            </>:null}            

            
            
{(this.state.tabActiveState == 'riskprofile' && this.state.listOrDomain =='list' && this.state.openRiskProfileAddCapabilityPanel == true)? <>

<div class="d-flex justify-content-start mt-3">

<div className={(this.state.listOrDomain =='list')? "ml-4 mr-2":"ml-4 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}} onClick={()=>this.listOrDomain('list')}><div className="col-xl-12 align-items-center " style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={(this.state.listOrDomain =='list')? require('./../../common/images/painpoint_initiative/sandwitch-selected-icon.png'):require('./../../common/images/painpoint_initiative/sandwitch-icon.png')} className="icon-img" alt="List" />}</span></div></a></div> 

<div className={(this.state.listOrDomain =='domain')? "ml-2 mr-2":"ml-2 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}} onClick={()=>this.listOrDomain('domain')}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={require('./../../common/images/painpoint_initiative/domain-icon.png')} className="icon-img" alt="Domain" />}</span></div></a></div> 

{(this.state.unselectedCapabilityModificationStarted || this.state.riskprofileCapabilityModificationStarted)? <>
  <div className="ml-auto mr-2" ><div className="d-flex justify-content-start" >{(this.state.checkedRiskprofileCapabilityData.length == 0 && this.state.checkedUnselectedCapabilityData.length == 0) ? <> <a href="javascript:void(0);" style={{"color":"#26262C"}} onClick={this.progressDone.bind(this)}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"0px", "width":"100px" }} type="button" className="btn btn-success">{<img src={require('./../../common/images/painpoint_initiative/progress_done.png')} className="icon-img" alt="Done" />} Done</button></div></a> </> : <> <a href="javascript:void(0);" style={{"color":"#26262C","cursor":"default"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"0px", "width":"100px" }} type="button" className="btn btn-disabled">{<img src={require('./../../common/images/painpoint_initiative/progress_notdone.png')} className="icon-img" alt="Done" />} Done</button></div></a>  </>}<a href="javascript:void(0);" onClick={this.progressCancel.bind(this)} style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"0px", "width":"100px" }} type="button" className="btn btn-outline-primary"><i className='fa'>&#xf00d;</i> Cancel</button></div></a></div></div>
</>: <> 
{/* <div className="ml-auto mr-2" ><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"10px" }} type="button" className={(this.state.riskprofileCapabilityData.length>0) ?"btn btn btn-outline-primary":"btn btn btn-disabled"}><i className='fa fa-edit'></i> Edit Risk Profile</button></div></a></div> */}
</>}
  

</div>

            <div class="d-flex justify-content-between align-items-center heatmap-header">
                 <div style={{width:'60%',"padding-left":"85px"}}>
                  <h2>Risk Profile Capabilities</h2>
                 </div>  
                 <div style={{width:'40%'}} align="right" >
                  
                  </div>
            </div>
            <div className="col-sm-12 heatmap-table table-responsive">
                  <table className="table mt-4 table-fixx-block" style={{"min-height":(this.state.riskprofileCapabilityData.length>0)?"240px":"100px"}}>
                    <thead className="thead-dark">                      
                      <tr style={{display:"table" ,width:"100%"}}>
                      <th style={{width:'5%', "padding":"14px 7px", "height":"62px" }}>
                        {(this.state.riskprofileCapabilityData.length>0 && this.state.checkedRiskprofileCapabilityData.length>0) ? <>
                          <a href="javascript:void(0);"  data-toggle="dropdown" role="button" aria-expanded="false" style={{"color":"#26262C"}}>{<img src={require('./../../common/images/painpoint_initiative/More.png')} className="icon-img" alt="List" />}</a>
                      <ul class="dropdown-menu" role="menu">
                        <li style={{"text-align":"right", "padding-right":"10px", "cursor":"pointer" }}><span><i className='fa'>&#xf00d;</i></span></li>                            
                          <li style={{"border-bottom":"1px solid #cecece", "padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.selectAllRiskProfileCapabilities(null)}>Select All</a></li>                          
                          <li style={{"border-bottom":"1px solid #cecece", "padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.removeCapabilitiesFromRiskProfile(null)}>Remove from Risk Profile</a></li>
                          <li style={{"padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.clearRiskProfileCapabilitiesSelection(null)}>Clear Selection</a></li>                           
                        </ul>
                        </> :null}
                        
                      </th>
                        <th style={{width:'10%', "padding":"14px 7px" }}>SL No.</th>
                        <th style={{width:'20%' }}>Capability</th>
                        <th style={{width:'20%' }}>Domain</th>
                        <th style={{width:'20%' }}>AFly Score <a href="javascript:void(0);" onClick={()=>this.riskprofileAFlyScoreSort()} style={{"color":"#000000"}}><i className='fa fa-sort' style={{"font-size":"14px", "color": "#000000"}}></i></a></th>
                        <th style={{width:'20%' }}>Priority <a href="javascript:void(0);" onClick={()=>this.riskprofilePrioritySort()} style={{"color":"#000000"}}><i className='fa fa-sort' style={{"font-size":"14px", "color": "#000000"}}></i></a></th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.riskprofileCapabilityData.length>0?riskprofilecapabilitytableData:<tr>
                      <td colSpan={6} style={{width:'1032px', paddingTop:"35px" }}>You have not yet added any capability into your Risk Profile.</td>
                      </tr>}                      
                    </tbody>
                  </table>
                </div>
                <div class="d-flex justify-content-between align-items-center heatmap-header">
                 <div style={{width:'60%',"padding-left":"85px"}}>
                  <h2>Unselected Capabilities</h2>
                 </div>  
                 <div style={{width:'40%'}} align="right" >
                  
                  </div>
            </div>
            <div className="col-sm-12 heatmap-table table-responsive" style={{"margin-bottom":"60px"}}>
                  <table className="table mt-4 table-fixx-block" style={{"min-height":"300px"}}>
                    <thead className="thead-dark">                      
                    <tr style={{display:"table" ,width:"100%"}}>
                      <th style={{width:'5%', "padding":"14px 7px", "height":"62px" }}>
                      {(this.state.unSelectedCapabilityData.length>0 && this.state.checkedUnselectedCapabilityData.length>0) ? <>
                        <a href="javascript:void(0);"  data-toggle="dropdown" role="button" aria-expanded="false" style={{"color":"#26262C"}}>{<img src={require('./../../common/images/painpoint_initiative/More.png')} className="icon-img" alt="List" />}</a>
                      <ul class="dropdown-menu" role="menu">
                        <li style={{"text-align":"right", "padding-right":"10px", "cursor":"pointer" }}><span><i className='fa'>&#xf00d;</i></span></li>                            
                          <li style={{"border-bottom":"1px solid #cecece", "padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.selectAllUnselectedCapabilities(null)}>Select All</a></li>
                          <li style={{"border-bottom":"1px solid #cecece", "padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.addCapabilitiesToRiskProfile(null)}>Add to Risk Profile</a></li>
                          <li style={{"padding":"10px 0"}}><a className="dropdown-item" style={{fontSize : "14px", cursor: "pointer"}}  onClick={()=>this.clearUnselectedCapabilitiesSelection(null)}>Clear Selection</a></li>                           
                        </ul>
                      </>:null}                        
                      </th>
                        <th style={{width:'10%', "padding":"14px 7px" }}>SL No.</th>
                        <th style={{width:'20%' }}>Capability</th>
                        <th style={{width:'20%' }}>Domain</th>
                        <th style={{width:'20%' }}>AFly Score <a href="javascript:void(0);" onClick={()=>this.unselectedAFlyScoreSort()} style={{"color":"#000000"}}><i className='fa fa-sort' style={{"font-size":"14px", "color": "#000000"}}></i></a></th>
                        <th style={{width:'20%' }}>Priority <a href="javascript:void(0);" onClick={()=>this.unselectedPrioritySort()} style={{"color":"#000000"}}><i className='fa fa-sort' style={{"font-size":"14px", "color": "#000000"}}></i></a></th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.unSelectedCapabilityData.length>0?unselectedcapabilitytableData:<tr>
                        <td colSpan={6} style={{width:'1032px', paddingTop:"35px" }}>No Capability Available</td>
                      </tr>}
                    </tbody>
                  </table>
                </div>
            </>:null}

            
{(this.state.tabActiveState == 'riskprofile' && this.state.listOrDomain =='domain')? <>
<div class="d-flex justify-content-start mt-3">

<div className={(this.state.listOrDomain =='list')? "ml-4 mr-2":"ml-4 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}} onClick={()=>this.listOrDomain('list')}><div className="col-xl-12 align-items-center " style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={(this.state.listOrDomain =='list')? require('./../../common/images/painpoint_initiative/sandwitch-selected-icon.png'):require('./../../common/images/painpoint_initiative/sandwitch-icon.png')} className="icon-img" alt="List" />}</span></div></a></div> 

<div className={(this.state.listOrDomain =='domain')? "ml-2 mr-2":"ml-2 mr-2"}  style={{"width":"50px"}}><a href="javascript:void(0);" style={{"color":"#26262C"}} onClick={()=>this.listOrDomain('domain')}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><span>{<img src={(this.state.listOrDomain =='domain')? require('./../../common/images/painpoint_initiative/domain_selected.png'):require('./../../common/images/painpoint_initiative/domain-icon.png')} className="icon-img" alt="List" />}</span></div></a></div> 

{(this.state.unselectedCapabilityModificationStarted || this.state.riskprofileCapabilityModificationStarted)? <>
  <div className="ml-auto mr-2" ><div className="d-flex justify-content-start" >{(this.state.checkedRiskprofileCapabilityData.length == 0 && this.state.checkedUnselectedCapabilityData.length == 0) ? <> <a href="javascript:void(0);" style={{"color":"#26262C"}} onClick={this.progressDone.bind(this)}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"0px", "width":"100px" }} type="button" className="btn btn-success">{<img src={require('./../../common/images/painpoint_initiative/progress_done.png')} className="icon-img" alt="Done" />} Done</button></div></a> </> : <> <a href="javascript:void(0);" style={{"color":"#26262C","cursor":"default"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"0px", "width":"100px" }} type="button" className="btn btn-disabled">{<img src={require('./../../common/images/painpoint_initiative/progress_notdone.png')} className="icon-img" alt="Done" />} Done</button></div></a>  </>}<a href="javascript:void(0);" onClick={this.progressCancel.bind(this)} style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"0px", "width":"100px" }} type="button" className="btn btn-outline-primary"><i className='fa'>&#xf00d;</i> Cancel</button></div></a></div></div>
</>: <> 
{/* <div className="ml-auto mr-2" ><a href="javascript:void(0);" style={{"color":"#26262C"}}><div className="col-xl-12" style={{"text-align":"center", "padding-top":"10px"}}><button style={{"padding":"3px 7px", "margin-right":"10px" }} type="button" className={(this.state.riskprofileCapabilityData.length>0) ?"btn btn btn-outline-primary":"btn btn btn-disabled"}><i className='fa fa-edit'></i> Edit Risk Profile</button></div></a></div> */}
</>}
  

</div>
<div class="d-flex justify-content-between align-items-center heatmap-header">
        <div style={{width:'60%',"padding-left":"85px"}}>
        <h2>Risk Profile Capabilities</h2>
        </div>  
        <div style={{width:'40%'}} align="right" >
        
        </div>
  </div>
  <div className="col-sm-12 heatmap-table table-responsive" style={{"margin-bottom":"60px"}}>                  
    {this.state.riskprofileCapabilityData.length>0?riskprofilecapabilityDomainviewtableData:<tr>
                      <td colSpan={6} style={{width:'1032px', paddingTop:"35px" }}>You have not yet added any capability into your Risk Profile.</td>
                      </tr>}
  </div>


<div class="d-flex justify-content-between align-items-center heatmap-header">
        <div style={{width:'60%',"padding-left":"85px"}}>
        <h2>Unselected Capabilities</h2>
        </div>  
        <div style={{width:'40%'}} align="right" >
        
        </div>
  </div>
  <div className="col-sm-12 heatmap-table table-responsive" style={{"margin-bottom":"60px"}}>                  
    {this.state.unSelectedCapabilityData.length>0?unselectedcapabilityDomainviewtableData:null}
  </div>
</>:null}

  
  
{(this.state.tabActiveState == 'activity' && this.state.listOrDomain =='list')? <>

    <div class="col-sm-12 heatmap-table table-responsive">
          <table class="table mt-4 table-fixx-block">
            <thead class="thead-dark">                      
              <tr style={{display:"table" ,width:"100%"}}>
              <th style={{width:'5%' }}>SL NO.</th>
              <th style={{width:'25%' }}>Capability</th>
              <th style={{width:'15%' }}>Updated By</th>
              <th style={{width:'15%' }}>Last Updated Date</th>
              </tr>
            </thead>
            <tbody>
            {this.state.activityRiskprofileData.length>0?activityRiskprofiletableData:<tr>
              <td colSpan={4} style={{width:'1032px', paddingTop:"35px" }}>You have not yet added any capability into your Risk Profile.</td>
              </tr>} 
            </tbody>
          </table>
        </div>

    </>:null}

  {(this.state.tabActiveState == 'activity' && this.state.listOrDomain =='domain')? <>
  <div class="col-sm-12 mt-4 heatmap-table table-responsive"></div></>:null}

            </div>
          
          </div>
          </div>
          </div>
          </PainPointsWrapper>
                        </div>
                        </div>
                        </div>
                        <FooterComponent/>
                        </>
        )
    }
}
const mapStateToProps = state => ({
  // processData: state.capability.processData,
  // processes: state.capability.processes,
  // functions: state.capability.functions,
  // phases: state.capability.phases,
  // scores: state.capability.scores,
  // activity: state.capability.activity,
  // activityLocation: state.capability.activityLocation,
  // loadingstatus: state.capability.loadingstatus,
  // copySuccess: state.capability.copySuccess,
  // CLientAnalyticsUsecaseData:state.benchmarkingData.setCLientAnalyticsUsecaseData
  capabilityModellingProjectdropdownData: state.capability.items,
  painpointInitiatives:state.benchmarkingData.painpointInitiatives,
  capabilityPainPoints:state.benchmarkingData.capabilityPainPoints
});
export default connect(mapStateToProps,{resetInitiatives,setProject,fetchPainPointsInitiatives,GetCapabilityPainPoints, fetchProjects, startLoader, stopLoader})(PainPointInitiatives)