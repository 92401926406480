import React from 'react'
import Header from '../../Header/Header'
import Revenue_main from './Revenue_main'

const RevenueTwoBody = () => {
  return (
    <div>
        <Header title="Revenue Two" />
        <Revenue_main />
    </div>
  )
}

export default RevenueTwoBody
