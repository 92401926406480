import React from 'react'
import Header from "../Header/Header"
import DigitalManagmentMain from './DigitalManagmentMain'



const DigitalManagmentBody = () => {
  return (
    <div>
       <Header title="Digital Adoptoin & Financial Managament" />
       <DigitalManagmentMain />
    </div>
  )
}

export default DigitalManagmentBody
