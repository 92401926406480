import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select";


const balancedScorecardGrantChart = props => {
    let showactual = false;
    //console.log('--hb-- categoryNameKpiNameControlLevers');
    //console.log(props.categoryNameKpiNameControlLevers);
    /*console.log('--hb-- categoryRows');
    console.log(props.categoryRows); */
    

    let ddc= 0;

    let categoryKpiRows = props.categoryNameKpiNameControlLevers.map((row, rowInd)=>{
        

        let kpis = row.Kpi.map((kpi,ind)=>{
            
            /* ------ */
            let controlLevers = kpi.ControlLevers.map((controlLever,controlLeverInd)=>{
                //console.log(props.ddoptions);
                ddc++;
                return(                    
                <table className="ctable">
                    <tbody>
                    <tr className="nospacing thead-controllever">
                        <td className="nospacing somepadding" style={{width:"12%"}}>
                        <span className="pclass4">{controlLever.DependencyId}</span>
                        </td>
                        <td className="nospacing somepadding" style={{width:"40%"}}>
                        <span className="pclass4">{controlLever.ControlLeversTitle}</span> 
                        </td>
                    <td className="nospacing somepadding" style={{width:"40%"}}>                    
                        <p>                     
                        <input style={{width:"90%"}} type="text" className="form-control" placeholder="Serial number (comma separated)" 
                        value={controlLever.DisplayDependency}  onChange={(evt)=>props.changeSubKpiDependency(controlLever.KPIControlLeversID,evt,controlLever.DependencyId)} />
                        </p>
                    </td>
                    </tr>
                    </tbody>
                </table>);
            })
            /* ------ */

            let bgColor='';
        
            props.categoryChartLegend.map(leg=>{
                if(leg.legendID == kpi.KpiId)
                {
                    bgColor = leg.legendColor;
                }
            })

            return(
            <table className="ctable">
                <tbody>
                    <tr className="nospacing thead-kpiname" style={{backgroundColor:bgColor}}>                        
                        <td className="nospacing somepadding" style={{width:"12%", textAlign:"left"}}>
                        <span className="pclass3">{kpi.DependencyId}</span>
                        </td>
                        <td className="nospacing somepadding" style={{width:"80%", textAlign:"left"}}>
                            <span className="pclass3">{kpi.KpiName}</span>                            
                        </td>
                        <td className="nospacing"></td>
                    </tr>
                    <tr className="nospacing">
                        <td className="nospacing" colspan="3">{controlLevers}</td>
                    </tr>
                </tbody>
                </table>);
        })
    return(
            <tr className="nospacing">
                <td className="nospacing" colspan="3">
                 <table className="ctable">
                    <thead className="thead-categoryname" style={{backgroundColor:'#ffffbe'}}>
                        <tr className="nospacing">
                            <th className="nospacing somepadding" style={{textAlign:"left", width:"8%"}}>
                                <span className="pclass2">{row.DependencyId}</span>
                            </th>
                            <th className="nospacing somepadding" colspan="2" style={{textAlign:"left", width:"60%"}}>
                                <span className="pclass2">{row.title}</span>
                            </th>
                        </tr>
                    </thead>
                 <tbody>
                 <tr className="nospacing">
                    <td className="nospacing" colspan="3">{kpis}</td>
                </tr>
                 </tbody>
                </table>
                </td>
            </tr>
        )
    })

    return(
        <>
            <div className="bg-white balanced-details-row nopadding" style={{width:"100%"}}>            
                <div className="balanced-col-wraper">
                    <div className="row" >
                        <div className="table-responsive">
                            <table className="ctable objectives-tbl">
                                <thead className="thead-dark">
                                <tr className="nospacing">
                                    <th className="nospacing pl-10" style={{width:"12%", textAlign:"left"}}>
                                        <span className="pclass1">{" "}SERIAL NO.</span>
                                    </th>
                                    <th className="nospacing" style={{width:"40%",textAlign:"left"}}>
                                        <span className="pclass1">TASK</span>
                                    </th>
                                    <th className="nospacing" style={{width:"40%",textAlign:"left"}}>
                                        <span className="pclass1">DEPENDENCY</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {categoryKpiRows}                                                                                                      
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>


            </div>
        </>
    )
}

export default balancedScorecardGrantChart;