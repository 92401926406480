import React,{Component,useState,useEffect} from 'react';
import DashboardHeader from '../../../includes/dashboardHeader/DashboardHeader';
import DashboardSidebar from '../../../includes/dashboardSidebar/DashboardSidebar';
import SidebarJs from '../../../../common/js/sidebarAnimation';
import {getSignIn} from '../../../../common/js/ApiRequest';
import {getAccessToken,getEmbedToken,getAllReports,reInitialize,getBIReportId} from '../../../../actions/powerbiActions';
import {connect} from 'react-redux'
import $ from 'jquery'
import CacheStorage from '../../../../utils/CacheStorage'; 
import { useParams } from "react-router-dom";
const OpreationManagmentMain= (props) => {

const [state,setState] = useState({selectedReport:{},embedToken:"",embedUrl:"",accessToken:"",reports:[],reportId:"",pageName:""})
const params = useParams();
useEffect(()=>{
 //props.reInitialize()
    },[])
    useEffect(()=>{
        props.getAccessToken();
        props.getBIReportId();
    },[])
    useEffect(()=>{

const {powerbiData} = props;
const {powerbiAccessToken} = powerbiData;

if(
powerbiAccessToken.access_token && 
powerbiAccessToken.access_token!==""){
  
    CacheStorage.setItem("pbi-token",powerbiAccessToken.access_token);
    
    setState({
        ...state,
        accessToken:powerbiAccessToken.access_token,
        selectedReport:{embedUrl:powerbiData.powerbiReport.embedUrl+'&$filter=L/ProjectID eq '+params.id,
                reportId:powerbiData.powerbiReport.reportId.trim()}
    })
 
}
    },[props.powerbiData.powerbiAccessToken])



    useEffect(()=>{
        
        if(state.accessToken !== ""){
            
            if(state.selectedReport.hasOwnProperty("reportId")){
                props.getEmbedToken(state.selectedReport)
            }
            
        }
    },[state.selectedReport])
    
    useEffect(()=>{
        if(!state.selectedReport.hasOwnProperty("reportId")){
            return
        }
       
        var embedToken = props.powerbiData.powerbiEmbedToken.token;
        var models = window['powerbi-client'].models
        var permissions = models.Permissions.All;
            var config = {
                type: 'report',
                tokenType:models.TokenType.Embed,
                accessToken:embedToken,
                embedUrl: state.selectedReport.embedUrl,
                id: state.selectedReport.reportId,
                permissions: permissions,
                settings: {
                  panes: {
                    filters: {
                      visible: true
                    },
                    pageNavigation: {
                      visible: true
                    }
                  }
                }
            };
            console.log('Config object',config)
          var  embedContainer = $('#embedContainer')[0]
       
        // // Embed the report and display it within the div container.
        var report = window.powerbi.embed(embedContainer, config);
         
        // Report.off removes a given event handler if it exists.
        report.off("loaded");
         
        // Report.on will add an event handler which prints to Log window.
        
        report.on("loaded", function () {
          
        });
        report.off("pageChanged");
 
        // Report.on will add an event listener.
      
        // Report.off removes a given event handler if it exists.
        report.off("rendered");
         
        // Report.on will add an event handler which prints to Log window.
        report.on("rendered", function () {
          
        });
       
            
       

    },[props.powerbiData.powerbiEmbedToken])


        return(
            <>

               <div style={{width:'100%',height:'600px',marginTop: '-50px',marginBottom: '80px',overflow:'scroll'}} id="embedContainer"></div>
  
            </>
        )
  
}
const mapStateToProps = state => ({
    powerbiData:state.powerbiData
})
export default connect(mapStateToProps,{getAccessToken,getEmbedToken,getAllReports,reInitialize,getBIReportId})(OpreationManagmentMain);